import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Select2Multiple from "v-select2-multiple-component";
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import AdvancedLaravelVuePaginate from "advanced-laravel-vue-paginate";
import "advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css";
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "FerryList",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    AdvancedLaravelVuePaginate,
    Loading
  },

  data() {
    return {
      noDataMsg: CONFIG.NO_DATA_FOUND,
      searchData: {
        ferry_no: '',
        total_seats: null,
        status: null
      },
      status: 2,
      ferryData: {},
      total: null,
      isLoading: false,
      statusArr: null
    };
  },

  methods: {
    getFerryStatus() {
      axios.get('getFerryStatus')
        .then(res => {
          this.statusArr = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getFerry(page = 1) {
      axios.get('ferry/list?page=' + page, { params: { campus_id: this.$store.state.user.campus_id } })
        .then(res => {
          if (res.data) {
            this.ferryData = res.data;
            this.total = this.ferryData.meta.total;
          }
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    reset() {
      this.searchData = {}
      this.status = 2;
    },

    search(page = 1) {
      this.isLoading = true;
      this.status == 2 ? this.searchData.status = null : this.searchData.status = this.status;
      axios.get('ferry/search?page=' + page, {
        params: {
          ferry_no: this.searchData.ferry_no,
          total_seats: this.searchData.total_seats,
          status: this.searchData.status,
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then(res => {
          this.isLoading = false;
          if (res.data) {
            this.ferryData = res.data;
            this.total = this.ferryData.meta.total;
          }
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    excelDownload() {
      this.isLoading = true;
      this.status == 2 ? this.searchData.status = null : this.searchData.status = this.status;
      axios.get('/ferry/excelDownload', {
        params: {
          ferry_no: this.searchData.ferry_no,
          total_seats: this.searchData.total_seats,
          status: this.searchData.status,
          campus_id: this.$store.state.user.campus_id
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let date = new Date();
          fileLink.setAttribute('download', `ferry_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate() }.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    confirmDelete(id) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.delete(id);
      } else {
        console.log("no");
      }
    },

    delete(id) {
      this.isLoading = true;
      axios.delete(`ferry/delete/${id}`)
        .then(res => {
          if (res.data.success == true)
            this.getFerry();
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

  },

  mounted() {
    this.isLoading = true;
    this.getFerryStatus();
    this.getFerry();
  },
};
