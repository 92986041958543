import MainLayout from "@/components/layout/admin/MainLayout.vue";
import flatPickr from 'vue-flatpickr-component';
import Select2Multiple from "v-select2-multiple-component";
import Loading from "vue-loading-overlay";
import 'flatpickr/dist/flatpickr.css';
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import store from '@/store'

export default {
    name: "DamageLossesCreate",
    components: {
        MainLayout,
        flatPickr,
        Select2Multiple,
        Loading
    },

    data() {
        return {
            damageLossesData: {
                library_member_id: null,
                book_id: null,
                status: '',
                description: '',
            },
            isError: false,
            submitted: false,
            campusId: store.state.user.campus_master.campus_code,
            disabled: false,
            memberArr: null,
            bookArr: null,
            isLoading: false,
            noMemberMsg: '',
            noLibraryMemberMsg: '',
            noBookMsg: '',
            campus_id: this.$store.state.user.campus_id
        };
    },

    validations: {
        damageLossesData: {
            book_id: {
                required,
            },
            status: {
                required
            },
            description: {
                required,
            },
        },
    },

    computed: {
        isValidMember() {
            if (!Array.isArray(this.memberArr) || this.memberArr.length === 0) return false;
            return this.memberArr.some(member => 
                Number(member.id) === Number(this.damageLossesData.library_member_id)
            );
        },
        isValidBook() {
            if (!Array.isArray(this.bookArr) || this.bookArr.length === 0) return false;
            return this.bookArr.some(book => 
                Number(book.id) === Number(this.damageLossesData.book_id)
            );
        },
    },

    methods: {
        getMember() {
            let $defaultSelectOption = [{
                'id': 0,
                'text': 'Select Member'
            }];
            axios.get("/member/getList", {
                params: {
                    campus_id: this.$store.state.user.campus_id,
                }
            })
                .then(res => {
                    if (res.data.data.length > 0) {
                        this.memberArr = $defaultSelectOption.concat(res.data.data);
                    } else {
                        this.memberArr = $defaultSelectOption;
                    }
                    this.isLoading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getBookList() {
            this.isLoading = true;
            const campusId = this.$store.state.user.campus_id;
            axios.get(`book/booklistforchoose/${campusId}`)
                .then((res) => {

                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Book'
                    }];

                    if (res.data && res.data.data && res.data.data.length > 0) {
                        let bookOptions = res.data.data.map(book => ({
                            id: book.id,
                            text: book.title
                        }));

                        this.bookArr = $defaultSelectOption.concat(bookOptions);
                        this.noBookMsg = '';
                    } else {
                        this.bookArr = $defaultSelectOption;
                        this.noBookMsg = 'No Book for this campus.';
                    }

                    this.isLoading = false;
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    } else {
                        console.error("Error fetching book list:", error);
                    }
                    this.isLoading = false;
                });
        },

        validateForm() {
            this.errors = {};
            const integerValidator = (value) => Number.isInteger(Number(value));
        
            if (!this.damageLossesData.library_member_id) {
                this.errors.library_member_id = 'Library Member is required.';
            } else if (!integerValidator(this.damageLossesData.library_member_id)) {
                this.errors.library_member_id = 'LibraryMember must be an integer.';
            } else if (!this.isValidMember) {
                this.errors.library_member_id = 'Selected Library Member is not valid.';
            }

            if (!this.damageLossesData.book_id) {
                this.errors.book_id = 'Book is required.';
            } else if (!integerValidator(this.damageLossesData.book_id)) {
                this.errors.book_id = 'Book must be an integer.';
            } else if (!this.isValidBook) {
                this.errors.book_id = 'Selected Book is not valid.';
            }
        },

        create() {
            this.submitted = true;
            if (this.$v.$invalid || this.isError) {
                return;
            }
            if (this.validateForm()) {
                return;
            }
            this.disabled = true;
            axios.post('damageAndLoss/create', this.damageLossesData)
                .then(res => {
                    if (res.data.success == true) {
                        this.$notification.success("Damage and Losses Book Added Successfully", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.$router.push({
                            name: 'damageLossesList'
                        });
                    } else {
                        this.disabled = false;
                        this.$notification.error(res.data.message, {
                            timer: 3,
                            position: "bottomCenter",
                        });
                    }
                }).catch((error) => {
                    this.disabled = false;
                    this.isLoading = false;
                    if (error.response) {
                        if (error.response.status == 401) {
                            this.$store.commit('logout');
                            window.location.replace('login');
                        }
                        if (error.response.status === 422) {
                            this.$notification.error("Validation Error: " + error.response.data.message, {
                                timer: 5,
                                position: "bottomCenter"
                            });
                        }
                    }
                });
        },
    },
    mounted() {
        this.getMember();
        this.getBookList();
    }
};
