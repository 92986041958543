import MainLayout from "@/components/layout/admin/MainLayout.vue";

export default {
  name: "paymentCheckout",
  components: {
    MainLayout,
  },

  data() {
    return {

    };
  },

  computed: {

  },

  methods: {

  },
  mounted() {

  },
};
