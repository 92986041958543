import { render, staticRenderFns } from "./SchoolInfo.vue?vue&type=template&id=983c4738"
import script from "../controllers/school-info.js?vue&type=script&lang=js&external"
export * from "../controllers/school-info.js?vue&type=script&lang=js&external"
import style0 from "./SchoolInfo.vue?vue&type=style&index=0&id=983c4738&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports