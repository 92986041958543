import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import {
  required,
  maxLength,
  integer
} from "vuelidate/lib/validators";
import axios from 'axios';
import VueMonthlyPicker from 'vue-monthly-picker'
import store from "@/store";

export default {
  name: "SalaryCreate",
  components: {
    MainLayout,
    Select2Multiple,
    VueMonthlyPicker
  },

  data() {
    return {
      salaryData: {
        total_salary: 150000
      },
      allTea: [],
      submitted: false,
      disabled: false,
      selectedMonth: null,
      month: null
    };
  },

  watch: {
    selectedMonth(newValue) {
      if (newValue) {
        const adjustedMonth = new Date(newValue);
        adjustedMonth.setMonth(adjustedMonth.getMonth() + 1); // Add one month to adjust for the offset
        this.month = adjustedMonth.toISOString().slice(0, 7); // Extract month and year
      } else {
        this.month = null;
      }
    },
  },

  validations: {
    salaryData: {
      teacher_id: {
        required
      },
      total_salary: {
        required,
        integer,
        maxLength: maxLength(9)
      }
    },
    month: {
      required
    }
  },

  methods: {
    getTeacher() {
      axios.get('teacher/getallTeacher',{ params: { campus_id: this.$store.state.user.campus_id } } )
      .then(res => {
        if (res.data[0].length != 0) {
          this.allTea = res.data[0];
        }
      }).catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    getTotalSalary($teacherId) {
      axios.get("/salary/getTotalSalary/" + $teacherId, {
        params: {
          campus_id: store.state.user.campus_id
        }
      }).then((response) => {
        if (response.data != '') {
          this.salaryData.total_salary = response.data.total_salary;
        } 
        if (response.data == '') {
          this.salaryData.total_salary = 150000;
        }
        this.isLoading = false;
      })
      .catch((error) => {
         if (error.response.status == 401) {
           this.$store.commit('logout');
           window.location.replace('login');
         }
        this.isLoading = false;
      });
    },

    create() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }
      this.salaryData.login_id = this.$store.state.user.id;
      this.salaryData.campus_id = this.$store.state.user.campus_id;
      this.salaryData.month = this.month;
      this.disabled = true;
      axios.post('salary/create', this.salaryData)
        .then(res => {
          if (res.data.success == true) {
            this.$notification.success("Salary Added Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({ name: 'salaryList' })
          } else {
            this.disabled = false;
            this.$notification.error(res.data.message, {
              timer: 3,
              position: "bottomCenter"
            });
        }
          this.disabled = false;
        }).catch(error => {
          this.disabled = false;
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    }
  },

  mounted() {
     this.getTeacher();
  }
};
