import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import CONFIG from "@/assets/js/config.js";
import store from '../../store';

export default {
    name: "attendanceRateList",
    components: {
        MainLayout,
        flatPickr,
        Select2Multiple,
        AdvancedLaravelVuePaginate,
        Loading,
    },

    data() {
        return {
            attendanceRates: {},
            attendanceRateLimits: CONFIG.ATTENDANCE_RATE_LIMIT,
            noDataMsg: CONFIG.NO_DATA_FOUND,
            isLoading: false,
            search_status: false,
            campusId: '',
            academicYearArr: null,
            selectedYear: 0,
            selectedGrade: 0,
            selectedRateLimit: 0,
            selectedSection: 0,
            name: '',
            year: '',
            sectionArr: [{
              "id": 0,
              "text": "All"
            }],
            section_id: '',
            grade_id: '',
            gradeArr: [],
        };
    },

    methods: {
        getAttendanceRates(page = 1) {
            var request = {
                params: {
                    campus_id: store.state.user.campus_id
                }
            }
            axios.get("/student/attendance-rate/list?page=" + page, request)
                .then(response => {
                    this.search_status = false;
                    this.attendanceRates = response.data;
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Attendance Rate List API Error", error);
                });
        },

        reset() {
            this.name = ''
            this.year = this.grade_id = this.section_id = '';
            this.selectedYear = this.selectedGrade = this.selectedSection = this.selectedRateLimit = 0;
        },

        searchAttendanceRate(page = 1) {
            this.isLoading = true;
            var request = {
                params: {
                    grade_id: this.grade_id,
                    section_id: this.section_id,
                    campus_id: this.$store.state.user.campus_id,
                    year: this.year,
                    name: this.name,
                    rate_limit: this.selectedRateLimit,
                }
            }

            axios.get("/student/attendance-rate/list?page=" + page, request)
                .then(response => {
                    this.search_status = true;
                    this.attendanceRates = response.data;
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Attendance Rate List Search API Error", error);
                });
        },

        getAcademicYear() {
            axios.get('getAcademicYear', {
                params: {
                    campus_id: store.state.user.campus_id
                }
            }).then(res => {
                let $defaultSelectOption = [{
                    'id': 0,
                    'text': 'Select'
                }];
                this.academicYearArr = $defaultSelectOption.concat(res.data.academicYearArr);
            }).catch((error) => {
                if (error.response.status == 401) {
                  this.$store.commit('logout');
                  window.location.replace('login');
                }
                this.isLoading = false;
            })
        },

        getGradeNameByAcademicYear(yearId) {
          axios.get('getGradeNameByAcademicYear?id=' + yearId, {
            params: {
              campus_id: store.state.user.campus_id
            }
          }).then(res => {
            let $defaultSelectOption = [{
              "id": 0,
              "text": "All"
            }];
            this.gradeArr = $defaultSelectOption.concat(res.data);
            this.isLoading = false;
          }).catch((error) => {
            if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
            }
            this.isLoading = false;
          });
        },

        getSection($gradeId, $yearId) {
            axios.get("/class/getSection/" + $gradeId, {
                params: {
                    academic_year: $yearId,
                    campus_id: store.state.user.campus_id
                }
            })
            .then(res => {
                let $defaultSelectOption = [{
                    'id': 0,
                    'text': 'All'
                }];
                this.sectionArr = $defaultSelectOption.concat(res.data[0]);
                this.selectedSection = 0;
                this.isLoading = false;
            }).catch((error) => {
                if (error.response.status == 401) {
                    this.$router.push({
                        name: "login"
                    });
                }
                this.isLoading = false;
            });
        },

        changeEvent(val, fieldName) {
            this.$set(this, fieldName, val);
            if (fieldName == 'grade_id') {
                this.sectionArr = [];
                this.section_id = '';
                this.selectedSection = '';
                this.getSection(this.grade_id, this.year);
              }
              if (fieldName == 'year') {
                this.gradeArr = [];
                this.sectionArr = [];
                this.section_id = '';
                this.selectedSection = '';
                this.grade_id = '';
                this.selectedGrade = '';
                this.getGradeNameByAcademicYear(this.year);
              }
              if (fieldName == 'class_id') {
                this.proceed = false;
              }
              if (fieldName == 'year_id') {
                this.proceed = false;
                this.class_id = 0;
              }
        },

        excelDownload() {
            this.isLoading = true;
            axios.get('/student/attendance-rate/download', {
                params: {
                    grade_id: this.grade_id,
                    section_id: this.section_id,
                    campus_id: this.$store.state.user.campus_id,
                    year: this.year,
                    name: this.name,
                    rate_limit: this.selectedRateLimit,
                },
                responseType: 'arraybuffer'
            })
            .then(response => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                let date = new Date();
                fileLink.setAttribute('download', `attendance_rate_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate() }.xlsx`);
                document.body.appendChild(fileLink);
                fileLink.click();
                this.isLoading = false;
            }).catch(error => {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                this.isLoading = false;
            });
        },
    },
    mounted() {
        this.campusId = this.$store.state.user.campus_id;
        this.isLoading = true;
        this.getAttendanceRates();
        this.getAcademicYear();
    },
};
