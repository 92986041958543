import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";

export default {
  name: "TeacherProfile",
  components: {
    MainLayout,
    Loading
  },

  data() {
    return {
      isLoading: false,
      teacherData: {},
      noDataMsg: CONFIG.NO_DATA_EXIST,
      religionArr: null,
      academicYear: [],
      academic_year_a: '',
      hasSubject: false,
      subjectArr: [],
      attendanceInfo: {},
      courseInfo: {},
      defaultUrl: process.env.VUE_APP_STORAGE_PATH
    };
  },

  methods: {
    getThisAcademicYear() {
      axios.get('period/getThisPeriod')
        .then(res => {
          this.academicYear = res.data;
          this.getSubjectByTeacherId();
          this.getAttendanceInfo();
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getSubjectByTeacherId() {
      axios.get("subject/getSubjectByTeacherId/" + this.$route.params.id, {
        params: {
          acd_year_id: this.academicYear.id,
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then(response => {
          this.subjectArr = response.data;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Subject API Error", error);
        });
    },

    getReligion() {
      axios.get('getReligion')
        .then(res => {
          this.religionArr = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getTeacherDetail() {
      axios.get("/teacher/detail/" + this.$route.params.id, {
        params: {
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then((response) => {
          this.teacherData = response.data;
          if (this.teacherData.certificateInfo != null) {
            this.teacherData.certificateInfo.url = this.defaultUrl + this.teacherData.certificateInfo.url;
          }
          this.getCourseInfo();
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Teacher Detail API Error", error);
        });
    },

    getCourseInfo() {
      axios.get("/teacher/course/" + this.$route.params.id, {
        params: {
          acd_year_id: this.academicYear.id,
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then(response => {
          if (response.data.length > 0) {
            this.hasSubject = true;
            this.courseInfo = response.data;
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Course API Error", error);
        });
    },

    getAttendanceInfo() {
      var request = {
        params: {
          start_date: this.academicYear.start_date,
          teacher_id: this.$route.params.id,
          campus_id: this.$store.state.user.campus_id
        }
      }
      axios.get("/teacherAttendance/info", request)
        .then(response => {
          this.attendanceInfo = response.data;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Attendance API Error", error);
        });
    },

    confirmDelete(id) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.delete(id);
      }
    },

    delete(id) {
      axios.delete("/teacher/delete/" + id)
        .then(response => {
          if (response.data.success == true) {
            this.$router.push({ name: "teacherList" });
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          console.log("Teacher Delete Error->", error);
        });
    },

    downloadPdfForQrCode() {
      axios.get("/teacher/downloadPdf/" + this.$route.params.id, {
        params: {
          campus_id: this.$store.state.user.campus_id,
          t_name: this.teacherData.name,
          t_father_name: this.teacherData.father_name
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob'
      })
        .then(response => {
          if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            var currentDate = new Date();
            const fileName = `qrcode_${this.teacherData.name}_${currentDate.getFullYear()}${(currentDate.getMonth() + 1) > 9 ? '' : '0'}${currentDate.getMonth() + 1}${(currentDate.getDate()) > 9 ? '' : '0'}${currentDate.getDate()}.pdf`;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
        })
    },

    downloadFile() {
      window.open(this.teacherData.certificateInfo.url, '_blank');
    },
  },
  mounted() {
    this.isLoading = true;
    this.getThisAcademicYear();
    this.getReligion();
    this.getTeacherDetail();
  },
};
