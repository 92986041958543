<template>
  <footer class="footer">
      <div>
        <ul>
          <li><a href="/aboutUs" aria-current="page" class="router-link-exact-active router-link-active" target="_blank"><a>About Us</a></a></li>
          <li><a href="/privacyPolicy" aria-current="page" class="router-link-exact-active router-link-active" target="_blank"><a>Privacy Policy</a></a></li>
        </ul>
        <a href="https://bib-mm.com/" class="color" target="_blank"> <p>Powered by BIB Co., Ltd.</p> </a>
      </div>
  </footer>
</template>

<script>
export default {
  name: "FooterPage"
}
</script>
