<template>
  <section>
    <div class="wrapper">
      <div class="main-panel">
        <HeaderPage />
        <div class="content">
          <slot />
        </div>
        <Footer />
      </div>
    </div>
  </section>
</template>

<script>
import HeaderPage from "@/components/layout/admin/HeaderPage.vue";
import Footer from "@/components/layout/admin/FooterPage.vue";

export default {
  name: "MainLayout",
  components: {
    HeaderPage,
    Footer
  },
};
</script>
