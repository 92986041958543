import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Select2Multiple from "v-select2-multiple-component";
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import { required, maxLength, minLength, numeric } from "vuelidate/lib/validators";
import { ConvertNumService } from '@/assets/js/convertNumberService.js';
import CONFIG from "@/assets/js/config.js";
import axios from "axios";

const convertNumberService = new ConvertNumService();

export default {
  name: "DriverCreate",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
  },

  data() {
    return {
      imageLargeMsg: CONFIG.IMAGE_TOO_LARGE,
      config: {
        maxDate: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + (new Date().getDate() - 1)
      },
      driverData: {
        driver_img: '',
        driver_name: '',
        father_name: '',
        password: '',
        nrc_number: '',
        dob: null,
        work_experience: '',
        join_date: null,
        phone1: '',
        phone2: '',
        contact_address: '',
        status: 0
      },
      driver_img: '',
      nrcNameJson: null,
      naingJson: null,
      nrcno: null,
      nrc_number: "",
      nrc_only_number: "",
      nrc: "",
      nrckey: "",
      selectedNrcName: "",
      selectedNrcNaing: "",
      districtList: [],
      naingList: [],
      nrcName: [],
      submitted: false,
      disabled: false
    };
  },

  validations: {
    driverData: {
      driver_name: {
        required,
        maxLength: maxLength(100)
      },
      dob: {
        required,
        maxLength: maxLength(100),
      },
      father_name: {
        required,
        maxLength: maxLength(100)
      },
      phone1: {
        required,
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(11)
      },
      phone2: {
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(11)
      },
      contact_address: {
        required,
        maxLength: maxLength(500)
      },
      work_experience: {
        maxLength: maxLength(500)
      },
      join_date: {
        required,
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(20)
      },
      confirm_password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(20)
      },
    },
    nrc_number: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(6)
    },
  },

  methods: {
    async getNrcCity() {
      try {
        const response = await axios.get('getNrcCity')
        if (response) {
          this.nrcno = response.data;
        }
      } catch (error) {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      }
    },

    async getNrcNaing() {
      try {
        const response = await axios.get('getNrcNaing')
        if (response) {
          this.naingJson = response.data;
        }
      } catch (error) {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      }
    },

    async getNrcTownship() {
      try {
        const response = await axios.get('getNrcTownship')
        if (response) {
          this.nrcNameJson = response.data;
        }
      } catch (error) {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      }
    },

    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);
    },

    onChange(event) {
      var optionText = event.target.value;
      var convertNumberService = new ConvertNumService();
      var optionValue = convertNumberService.convertMm2Eng(optionText);
      if (optionValue) {
        this.nrckey = optionText;
        const placeholder = document.getElementById('nrc_placeholder');
        placeholder.style.display = 'none';
      } else {
        this.nrckey = optionValue;
      }
      this.selectedNrcName = "";
      this.districtList = this.changeNrcName(optionValue);
    },

    changeNrcName(value, nrcName) {
      nrcName = this.nrcNameJson[value];
      return nrcName;
    },

    onChangeNrcName(event) {
      var optionValue = event.target.value;
      if (!optionValue) {
        this.selectedNrcNaing = "";
        this.nrc_number = "";
      }
      this.naingList = this.naingJson;
    },

    checkNRC() {
      if (this.nrckey !== undefined && this.selectedNrcName !== undefined && this.selectedNrcNaing !== undefined && this.nrc_number !== "") {
        return true;
      } else if (this.nrckey == undefined && this.selectedNrcName == undefined && this.selectedNrcNaing == undefined && this.nrc_number == "") {
        return true;
      } else return false;
    },

    mergeNRC() {
      return convertNumberService.convertEng2Mm(this.nrckey + "/" + this.selectedNrcName + this.selectedNrcNaing + this.nrc_number);
    },

    chooseFiles(id) {
      document.getElementById(id).click();
    },

    uploadImage(id) {
      const inputfile = document.getElementById(id).files[0];
      if (inputfile) {
        if (inputfile.type.includes("image/")) {
          if (inputfile.type == "image/gif") {
            this.$notification.error("File not supported!", {
              timer: 3,
              position: "bottomCenter",
            });
            return null;
          }
          let reader = new FileReader();
          reader.readAsDataURL(inputfile);
          reader.onloadend = () => {
            if (reader.result.length > 5242880) {
              this.$notification.error(this.imageLargeMsg, {
                timer: 3,
                position: "bottomCenter",
              });
            } else {
              this.$set(this, id, reader.result);
            }
          };
        } else {
          this.$notification.error("File not supported!", {
            timer: 3,
            position: "bottomCenter",
          });
          return null;
        }
      }
    },

    removeImg(img) {
      this.$set(this, img, '');
      document.getElementById(img).value = '';
    },

    create() {
      this.submitted = true;
      if (this.$v.$invalid || this.checkNRC() == false) {
        return;
      } else if (
        this.driverData.password != this.driverData.confirm_password
      ) {
        this.$notification.error(
          "Password and confirm password do not match.",
          {
            timer: 3,
            position: "bottomCenter",
          }
        );
        return;
      }

      this.disabled = true;
      this.driverData.nrc_number = this.mergeNRC();
      this.driverData.loginId = this.$store.state.user.id;
      this.driverData.campus_id = this.$store.state.user.campus_id;
      this.driverData.driver_img = this.driver_img;

      axios.post('driver/create', this.driverData)
        .then(res => {
          if (res.data.success == true) {
            this.$notification.success("Driver Added Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({ name: 'driverList' })
          } else {
            this.disabled = false;
            this.$notification.error(res.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.disabled = false;
          this.errors = error.response.data.errors;
        })
    }


  },
  async mounted() { 
    await this.getNrcCity();
    await this.getNrcNaing();
    await this.getNrcTownship();
  },
};
