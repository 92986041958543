import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "parentDetail",
  components: {
    MainLayout,
    Loading
  },

  data() {
    return {
      parentData: [],
      isLoading: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
    };
  },

  methods: {
    getDetail() {
      axios.get(`parent/detail/${this.$route.params.id}`)
      .then(res => {
        if (res.data) {
          this.parentData = res.data[0];
        }
        this.isLoading = false;
      })
      .catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
        console.log("Parent Detail API Error", error);
      })
    },

    showStudentCreate(parentId) {
      this.$router.push({
        name: "studentCreate",
        params: {
          parentId: parentId
        }
      });
    },
  },

  mounted() {
    this.isLoading = true;
    this.getDetail();
  },
};
