import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import axios from "axios";

export default {
  name: "checkoutDetail",
  components: {
    MainLayout,
    Loading
  },

  data() {
    return {
      noDataMsg: CONFIG.NO_DATA_EXIST,
      bookTypeOptions: CONFIG.BOOK_TYPE,
      isLoading: false,
      checkoutData: {},
      qrCodeCounts: {}
    };
  },

  methods: {
    isReturnDateOver(returnDate, endDate) {
      if (!returnDate || !endDate) return false; // if either date is not set, don't show in red
      return new Date(returnDate.split(' ')[0]) > new Date(endDate);
    },
    
    getCheckoutDetailById() {
      axios.get('checkout/detail/' + this.$route.params.id)
        .then((response) => {
          if (response.data != undefined) {
            this.checkoutData = response.data;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getBookTitle(detail) {
      const qrCodeNo = detail.book.qr_code_no;

      if (!this.qrCodeCounts[qrCodeNo]) {
        this.qrCodeCounts[qrCodeNo] = 0;
      }

      this.qrCodeCounts[qrCodeNo] += 1;

      if (this.qrCodeCounts[qrCodeNo] > 1) {
        return `${detail.book.title} - ${this.qrCodeCounts[qrCodeNo]}`;
      } else {
        return `${detail.book.title}`;
      }
    },

    showList() {
      this.$router.push({
        path: "/checkout/list"
      });
    },
  },
  mounted() {
    this.isLoading = true;
    this.getCheckoutDetailById();
  },
};
