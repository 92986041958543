import MainLayout from "@/components/layout/admin/MainLayout.vue";
import store from '../../store';
import axios from "axios";
import {
    required,
    maxLength
} from "vuelidate/lib/validators";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: "PaymentMethodCreate",
    components: {
        MainLayout,
        Loading
    },

    data() {
        return {
            isLoading: false,
            payment_method: "",
            description: "",
            submitted: false,
            disabled: false,
            methodExists: false,
        };
    },

    validations: {
        payment_method: {
            required,
            maxLength: maxLength(100)
        },
        description: {
            maxLength: maxLength(1000)
        },
    },

    methods: {
        async checkDuplicateMethod() {
            try {
                const response = await axios.get("/paymentMethod/checkDuplicateMethod", {
                    params: {
                        payment_method: this.payment_method,
                    },
                });
                this.methodExists = response.data.exists;
            } catch (error) {
                console.error('An error occurred:', error);
            }
        },

        async confirmRegister() {
            this.submitted = true;
            this.disabled = true;
            await this.checkDuplicateMethod();
            if (this.$v.$invalid || this.payment_method == '' || this.payment_method == 0 || this.methodExists) {
                this.disabled = false;
                return;
            }
            this.register();
        },

        register() {
            this.disabled = true;
            var request = {
                payment_method: this.payment_method,
                description: this.description,
                login_id: store.state.user.id,
                campus_id: store.state.user.campus_id,
            }
            axios.post("/paymentMethod/create", request, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(response => {
                    if (response.data) {
                        this.$notification.success("Payment Method Added Successfully", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        if (this.$route.query.rd_back == 'paymentMethod') {
                            this.$router.push({
                                name: "paymentMethodCreate"
                            });
                        } else {
                            this.$router.push({
                                name: "paymentMethodList"
                            });
                        }
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.disabled = false;
                    this.
                        console.log("Payment Method Create API Error", error.errors);
                });
        },

        showList() {
            this.$router.push({
                path: "/paymentMethod/list"
            });
        },
    },

};
