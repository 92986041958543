import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import axios from 'axios';
import store from '@/store';

export default {
  name: "periodList",
  components: {
    MainLayout,
    flatPickr,
    Loading,
    CONFIG,
    AdvancedLaravelVuePaginate
  },

  data() {
    return {
      periodData: {},
      searchData: {
        start_date: null,
        end_date: null
      },
      total: null,
      isLoading: false,
      noDataMsg: CONFIG.NO_DATA_FOUND,
      adminNo: store.state.user.admin_no,
      campusId: store.state.user.campus_id,
    };
  },

  methods: {
    getPeriod(page = 1) {
      axios.get('period/list?page=' + page)
        .then(res => {
          if (res.data) {
            this.periodData = res.data;
            this.total = this.periodData.meta.total;
          }
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        })
    },

    // confirmDelete(id) {
    //   if (window.confirm("Are you sure you want to delete?")) {
    //     this.delete(id);
    //   } else {
    //     console.log("no");
    //   }
    // },

    // delete(id) {
    //   this.isLoading = true;
    //   axios.delete(`period/delete/${id}`)
    //     .then(res => {
    //       if (res.data)
    //         this.getPeriod();
    //     }).catch(error => {
    //       if (error.response.status == 401) {
    //         this.$store.commit('logout');
    //         window.location.replace('login');
    //       }
    //       this.isLoading = false;
    //     })
    // },

    reset() {
      this.searchData.start_date = '';
      this.searchData.end_date = '';
    },

    search(page = 1) {
      this.isLoading = true;
      axios.get('period/search?page=' + page, {
        params: {
          start_date: this.searchData.start_date,
          end_date: this.searchData.end_date
        }
      })
        .then(res => {
          if (res.data) {
            this.periodData = res.data;
            this.total = this.periodData.meta.total;
          }
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        })
    },

    excelDownload() {
      this.isLoading = true;
      axios.get('/period/excelDownload', {
        params: {
          start_date: this.searchData.start_date,
          end_date: this.searchData.end_date
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          this.isLoading = false;
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let date = new Date();
          fileLink.setAttribute('download', `period_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate()}.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.isLoading = true;
    this.getPeriod();
  },
};
