import { render, staticRenderFns } from "./TimetableMetaDetail.vue?vue&type=template&id=10722be4"
import script from "../../controllers/timetable_meta/timetable-meta-detail.js?vue&type=script&lang=js&external"
export * from "../../controllers/timetable_meta/timetable-meta-detail.js?vue&type=script&lang=js&external"
import style0 from "./TimetableMetaDetail.vue?vue&type=style&index=0&id=10722be4&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports