import store from '@/store';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import {
  required,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import Select2Multiple from 'v-select2-multiple-component';
import Footer from "@/components/layout/admin/FooterPage.vue";
import axios from 'axios';

export default {
  name: "LoginPage",

  components: {
    Footer,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      loginForm: {
        campus_id: 0,
        loginId: "",
        password: "",
      },
      campusArr: [],
      submitted: false,
      validation_error: false,
      error: "",
      isLoading: false,
      disabled: false,
    };
  },

  validations: {
    loginForm: {
      campus_id: {
        required
      },
      loginId: {
        required
      },
      password: {
        required,
        maxLength: maxLength(20),
        minLength: minLength(8)
      },
    },
  },

  methods: {
    campusNameAction() {
      axios.get('campus/loginCampus')
        .then(res => {
          this.isLoading = false;
          this.campusArr = res.data.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    idWithCampus() {
      if (this.loginForm.loginId === '0001') {
        this.loginForm.campus_id = 1;
      }
    },

    submitLogin() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }

      const { loginId } = this.loginForm;
      let errorMessage = null;

      switch (true) {
        case loginId.startsWith('P'):
          errorMessage = "Parent can not login";
          break;
        case loginId.startsWith('T'):
          errorMessage = "Teacher can not login";
          break;
        default:
          this.disabled = true;
          break;
      }

      if (errorMessage) {
        this.$notification.error(errorMessage, {
          timer: 3,
          position: "bottomCenter"
        });
      } else {
        store.dispatch("login", this.loginForm)
          .then(() => {
            let adminNo = this.$store.state.user.admin_no;
            if (adminNo == '0001') {
              window.location.replace('/admin/list');
            } else {
              window.location.replace('/');
            }
          })
          .catch((error) => {
            this.isLoading = false;
            this.disabled = false;
            if (error.response.status == 401) {
              if (error.response.data.message == CONFIG.OTHER_LOGIN_FAILED) {
                this.$notification.error(error.response.data.message, {
                  timer: 3,
                  position: "bottomCenter"
                });
              } else {
                this.$store.commit('logout');
                window.location.replace('login');
              }
            }

            this.validation_error = true;
            if (error.response.data.status == 400) {
              this.error = error.response.data.message;
            } else {
              if (error.response.data.message != CONFIG.OTHER_LOGIN_FAILED) {
                this.error = "Incorrect Login Number or Password";
              }
            }
          });
      }
    },
  },

  mounted() {
    this.isLoading = true;
    this.campusNameAction();
  },
};
