import flatPickr from 'vue-flatpickr-component';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import CONFIG from "@/assets/js/config.js";
import store from '@/store';
import 'flatpickr/dist/flatpickr.css';
import 'vue-loading-overlay/dist/vue-loading.css';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';

export default {
  name: "noticeList",
  components: {
    Select2Multiple,
    MainLayout,
    flatPickr,
    AdvancedLaravelVuePaginate,
    Loading,
    CONFIG
  },

  data() {
    return {
      noticeData: {},
      classArr: [],
      noticeArr: null,
      sendByArr: null,
      searchData: [],
      isLoading: false,
      noDataMsg: CONFIG.NO_DATA_FOUND,
      campusId: store.state.user.campus_id,
      time_config: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
      },
    };
  },

  methods: {
    getNoticeArr() {
      axios.get('getNotice')
        .then(res => {
          this.noticeArr = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getAdminRole() {
      axios.get('getAdminRole')
        .then(res => {
          this.sendByArr = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getClass() {
      axios.get('class/getGradeAndClass', { params: { campus_id: this.campusId } })
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'All'
          }];
          this.classArr = $defaultSelectOption.concat(res.data);
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getNotice(page = 1) {
      axios.get('notice/list?page=' + page, { params: { campus_id: this.campusId } })
        .then(res => {
          if (res.data) {
            this.noticeData = res.data;
          }
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    confirmDelete(id) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.delete(id);
      } else {
        console.log("no");
      }
    },

    delete(id) {
      this.isLoading = true;
      axios.post(`notice/delete/${id}` + '/' + store.state.user.campus_id)
        .then(res => {
          if (res.data)
            this.search();
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    reset() {
      this.searchData = [];
    },

    search(page = 1) {
      this.isLoading = true;
      axios.get('notice/search?page=' + page, {
        params: {
          title: this.searchData.title,
          sendBy: this.searchData.sendBy,
          sendTo: this.searchData.sendTo,
          start_date: this.searchData.start_date,
          end_date: this.searchData.end_date,
          start_time: this.searchData.start_time,
          end_time: this.searchData.end_time,
          name: this.searchData.name,
          class: this.searchData.class,
          campus_id: this.campusId
        }
      })
        .then(res => {
          this.isLoading = false;
          if (res.data) {
            this.noticeData = res.data;
          }
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    excelDownload() {
      this.isLoading = true;
      axios.get('/notice/excelDownload', {
        params: {
          title: this.searchData.title,
          sendBy: this.searchData.sendBy,
          sendTo: this.searchData.sendTo,
          start_date: this.searchData.start_date,
          end_date: this.searchData.end_date,
          start_time: this.searchData.start_time,
          end_time: this.searchData.end_time,
          name: this.searchData.name,
          class: this.searchData.class,
          campus_id: this.campusId
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let date = new Date();
          fileLink.setAttribute('download', `notice_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate() }.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    }

  },
  mounted() {
    this.campusId = this.$store.state.user.campus_id;
    this.isLoading = true;
    this.getAdminRole();
    this.getNoticeArr();
    this.getClass();
    this.getNotice();
  },
};
