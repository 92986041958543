import { render, staticRenderFns } from "./GradeFeeCreate.vue?vue&type=template&id=5c5a4873"
import script from "../../controllers/grade_fee/grade-fee-create.js?vue&type=script&lang=js&external"
export * from "../../controllers/grade_fee/grade-fee-create.js?vue&type=script&lang=js&external"
import style0 from "./GradeFeeCreate.vue?vue&type=style&index=0&id=5c5a4873&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports