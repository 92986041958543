import { render, staticRenderFns } from "./SubjectList.vue?vue&type=template&id=e340154a"
import script from "../../controllers/subject/subject-list.js?vue&type=script&lang=js&external"
export * from "../../controllers/subject/subject-list.js?vue&type=script&lang=js&external"
import style0 from "./SubjectList.vue?vue&type=style&index=0&id=e340154a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports