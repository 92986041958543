import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Select2Multiple from "v-select2-multiple-component";
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import AdvancedLaravelVuePaginate from "advanced-laravel-vue-paginate";
import "advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css";
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "FerryPickeupList",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    AdvancedLaravelVuePaginate,
    Loading
  },

  data() {
    return {
      noDataMsg: CONFIG.NO_DATA_FOUND,
      searchData: {},
      userData: {},
      routeArr: [],
      pointArr: [],
      isLoading: false,
      total: null
    };
  },

  methods: { 
    getData(page = 1) {
      axios.get('ferryPickup/getData?page=' + page, { params: { campus_id: this.$store.state.user.campus_id } })
        .then(res => {
          if (res.data) {
            this.userData = res.data;
            this.total = this.userData.meta.total;
          }
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getRoute() {
      axios.get('route/getRouteForRoutePoint', {
        params: {
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then(res => {
          this.isLoading = false;
          this.routeArr = res.data.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    routePointByRoute(id) {
      axios.get(`routePoint/getPointByRoute/${id}`, { params: { campus_id: this.$store.state.user.campus_id } })
        .then((res) => {
          if (res.data) {
            this.pointArr = res.data.data;
            this.disabled = false;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    reset() {
      this.searchData = {}
    },

    search(page = 1) {
      this.isLoading = true;
      axios.get('ferryPickup/search?page=' + page, {
        params: {
          father_name: this.searchData.father_name,
          user_name: this.searchData.user_name,
          route_id: this.searchData.route_id,
          route_point_id: this.searchData.route_point_id,
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then(res => {
          this.isLoading = false;
          if (res.data) {
            this.userData = res.data;
            this.total = this.userData.meta.total;
          }
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    excelDownload() {
      this.isLoading = true;
      axios.get('/ferryPickup/excelDownload', {
        params: {
          father_name: this.searchData.father_name,
          user_name: this.searchData.user_name,
          route_id: this.searchData.route_id,
          route_point_id: this.searchData.route_point_id,
          campus_id: this.$store.state.user.campus_id
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let date = new Date();
          fileLink.setAttribute('download', `ferry_pickup_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate()}.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    confirmDelete(pickup_id, user_id, user_type) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.delete(pickup_id, user_id, user_type);
      }
    },

    delete(pickup_id, user_id, user_type) {
      this.isLoading = true;
      axios.delete(`ferryPickup/delete/${pickup_id}`, {
        params: {
          user_id: user_id,
          user_type: user_type,
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then(res => {
          if (res.data.success == true)
            this.getData();
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
  },

  mounted() {
    this.isLoading = true;
    this.getData();
    this.getRoute();
  },
};
