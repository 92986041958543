import VueMonthlyPicker from 'vue-monthly-picker';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
import store from "@/store";

export default {
  name: "paymentDetail",
  components: {
    MainLayout,
    VueMonthlyPicker,
    Loading
  },

  data() {
    return {
      paymentData: [],
      campusId: store.state.user.campus_id,
      paymentMethodArr: null,
      paymentStatus : CONFIG.PAYMENT_STATUS,
      isLoading: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      student_name: '',
      monthlyPdfBtn: true,
    };
  },

  methods: {
    getPaymentMethod() {
      axios.get('getPaymentMethod')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Payment Method'
          }];
          this.paymentMethodArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
    
    getDetail() {
      axios.get('payment_request/detail/' + this.$route.params.id, {
        params: {
          class_id: this.$route.params.classId,
          campus_id: store.state.user.campus_id
        }
      }).then(res => {
        if (res.data.length == 0) {
          this.$notification.error("This payment does not exist on this campus", {
            timer: 3,
            position: "bottomCenter"
          });
          this.$router.push({
            name: "paymentRequestList"
          });
        } else {
          this.paymentData = res.data;
          this.student_name = res.data[0].studentName;
          this.month = null;
          this.isLoading = false;
        }
      })
        .catch(error => {
          this.isLoading = false;
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Payment Detail API Error", error);
        })
    },

    approve(paymentId) {
      axios.post(`payment_request/approve/${this.$route.params.id}`, {
        paymentId: paymentId
      })
        .then(res => {
          if (res.data.success) {
            if (res.data) {
              this.$notification.success("Payment request is approved successfully.", {
                timer: 3,
                position: "bottomCenter"
              });
              this.$router.push({
                name: "paymentRequestList"
              });
            }
          } else {
            this.$notification.error(res.data.message, {
              timer: 3,
              position: "bottomCenter"
            });
          }

        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.errors = error.response.data.errors;
          this.disabled = false;
          this.isLoading = false;
        })
    },

    reject(paymentId) {
      axios.post(`payment_request/reject/${this.$route.params.id}`, {
        paymentId: paymentId
      })
        .then(res => {
          console.log(res);
          if (res.data.success) {
            if (res.data) {
              this.$notification.success("Payment request is rejected successfully.", {
                timer: 3,
                position: "bottomCenter"
              });
              this.$router.push({
                name: "paymentRequestList"
              });
            }
          } else {
            this.$notification.error(res.data.message, {
              timer: 3,
              position: "bottomCenter"
            });
          }

        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.errors = error.response.data.errors;
          this.disabled = false;
          this.isLoading = false;
        })
    },

    openImage(imagePath) {
      window.open(imagePath, '_blank');
    },
  },

  mounted() {
    this.isLoading = true;
    this.getDetail();
    this.getPaymentMethod();
  },
};
