import MainLayout from "@/components/layout/admin/MainLayout.vue";
import store from '../../store';
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import {
    required,
    maxLength
} from "vuelidate/lib/validators";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: "PaymentMethodEdit",
    components: {
        MainLayout,
        Loading
    },

    data() {
        return {
            isLoading: false,
            disabled: false,
            paymentMethodData: {},
            noDataMsg: CONFIG.NO_DATA_EXIST,
            payment_method: '',
            description: '',
            submitted: false,
            methodExists: false,
        };
    },

    validations: {
        paymentMethodData: {
            payment_method: {
                required,
                maxLength: maxLength(100)
            },
            description: {
                maxLength: maxLength(1000)
            },
        },
    },

    methods: {
        async checkDuplicateMethod() {
            try {
                const response = await axios.get("/paymentMethod/checkDuplicateMethod", {
                    params: {
                        id: this.paymentMethodData.id,
                        payment_method: this.paymentMethodData.payment_method,
                    },
                });
                this.methodExists = response.data.exists;
            } catch (error) {
                console.error('An error occurred:', error);
            }
        },

        getPaymentMethodEdit() {
            axios.get("/paymentMethod/detail/" + this.$route.params.id)
                .then(response => {
                    this.paymentMethodData = response.data;
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Payment Method Detail API Error", error);
                });
        },

        async confirmUpdate() {
            this.submitted = true;
            await this.checkDuplicateMethod();
            if (this.$v.$invalid || this.paymentMethodData.payment_method == '' || this.paymentMethodData.payment_method == 0 || this.methodExists) {
                return;
            }
            this.update();
        },

        update() {
            this.disabled = true;
            var id = this.$route.params.id
            var request = {
                payment_method: this.paymentMethodData.payment_method,
                description: this.paymentMethodData.description,
                login_id: store.state.user.id,
            }
            axios.post("/paymentMethod/update/" + id, request, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(response => {
                    if (response.data) {
                        this.$notification.success("Payment Method Updated Successfully", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.$router.push({
                            name: "paymentMethodList"
                        });
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.disabled = false;
                    this.isLoading = false;
                    console.log("Payment Method Edit API Error", error.errors);
                });
        },

        showList() {
            this.$router.push({
                path: "/paymentMethod/list"
            });
        },
    },
    mounted() {
        this.isLoading = true;
        this.getPaymentMethodEdit();
    },
};
