import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import CONFIG from "@/assets/js/config.js";

export default {
    name: "subjectAssignList",
    components: {
        MainLayout,
        flatPickr,
        Select2Multiple,
        Loading
    },

    data() {
        return {
            isLoading: false,
            submitted: false,
            disabled: false,
            teacher: "",
            grade: "",
            section: "",
            subject: "",
            is_classroom_teacher: false,
            subjectTeacherInfo: {},
            teacherArr: [],
            campusGradeArr: [],
            sectionArr: [],
            subjectArr: [],
            selectedTeacher: 0,
            selectedGrade: 0,
            selectedSection: 0,
            selectedSubject: "",
            noDataMsg: CONFIG.NO_DATA_EXIST,
            section_exist: '',
        };
    },

    methods: {
        changeEvent(val, fieldName) {
            this.$set(this, fieldName, val);

            if (fieldName == 'subject' && val.length == 0) {
                this.$set(this, fieldName, 0);
            }

            if (fieldName == "grade") {
                // this.isLoading = true;
                this.getSection(val, 0);
                this.getSubject(val, 0);
            }

            if (fieldName == "section") {
                this.section_exist = '';
            }
        },

        getAllTeacher() {
            var request = {
                params: {
                    campus_id: this.$store.state.user.campus_id
                }
            }
            axios
                .get("/teacher/getallTeacher", request)
                .then((response) => {
                    let $defaultSelectOption = [
                        {
                            id: 0,
                            text: "Select Teacher Name",
                        },
                    ];
                    this.teacherArr = $defaultSelectOption.concat(response.data[0]);
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getCampusGradeName(campus_id = 1) {
          axios.get('getCampusGradeName?id=' + campus_id, {
              params: {
                  campus_id: this.$store.state.user.campus_id
              }
          }).then(res => {
              let $defaultSelectOption = [{
                  "id": 0,
                  "text": "Select"
              }];
              this.campusGradeArr = $defaultSelectOption.concat(res.data);
              this.isLoading = false;
          }).catch((error) => {
              if (error.response.status == 401) {
                  this.$store.commit('logout');
                  window.location.replace('login');
              }
              this.isLoading = false;
          });
      },

        getSubjectTeacherData() {
            axios.get("/subjectTeacher/detail/" + this.$route.params.id)
                .then(response => {
                    this.subjectTeacherInfo = response.data;
                    this.selectedTeacher = this.teacher = this.subjectTeacherInfo.teacher_id;
                    this.selectedGrade = this.grade = this.subjectTeacherInfo.campus_grade_id;
                    this.selectedSection = this.section = this.subjectTeacherInfo.class_id;
                    this.selectedSubject = this.subject = this.subjectTeacherInfo.subject_id;
                    this.is_classroom_teacher = response.data.is_classroom_teacher;
                    this.getSection(this.grade, this.selectedSection);
                    this.getSubject(this.grade, this.selectedSubject);
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Subject Assign Detail API Error", error);
                });
        },

        getSection($campusGradeId, $selectedId) {
            var request = {
                params: {
                    campus_id: this.$store.state.user.campus_id,
                     campus_grade_id: $campusGradeId
                }
            }
            axios
                .get("/class/sectionList", request)
                .then((response) => {
                    if (response.data.length > 0) {
                        this.sectionArr = [
                            {
                                id: 0,
                                text: "Choose Section",
                            },
                        ].concat(response.data);
                        this.selectedSection = this.section = $selectedId;
                    } else {
                        this.sectionArr = [
                            {
                                id: 0,
                                text: "Choose Section",
                            },
                        ];
                        this.selectedSection = this.section = 0;
                    }
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Section API Error", error);
                });
        },
        getSubject($gradeId, $selectedId) {
            var request = {
                params: {
                    campus_id: this.$store.state.user.campus_id
                }
            }
            axios
                .get("subject/subjectByGradeId/" + $gradeId, request)
                .then((response) => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Subject'
                    }];
                    this.subjectArr = $defaultSelectOption.concat(response.data.subjectArr[0]);
                    this.selectedSubject = this.subject = $selectedId;
                    this.isLoading = false;
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Subject API Error", error);
                });
        },
        confirmUpdate() {
            this.submitted = true;
            if (this.teacher == 0 ||
                this.teacher == "" ||
                this.subject == 0 ||
                this.subject == "" ||
                this.section == 0 ||
                this.section == "") {
                return;
            }
            this.update();
        },

        update() {
            this.disabled = true;
            var request = {
                id: this.$route.params.id,
                teacher: this.teacher,
                grade: this.grade,
                section: this.section,
                subject: this.subject,
                is_classroom_teacher: this.is_classroom_teacher,
                campus_id: this.$store.state.user.campus_id,
                loginId: this.$store.state.user.id
            }

            axios.post("/subjectTeacher/update", request, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(response => {
                    if (response.status == 204) {
                        this.submitted = false;
                        this.disabled = false;
                        this.getSection(this.grade, 0);
                        this.section_exist = "Section doesn't exist. Please choose other section.";
                        return;
                    }
                    if (response.data.success == true) {
                        this.$notification.success("Subject Assign Updated Successfully", {
                            timer: 3,
                            position: "bottomCenter",
                        });
                        this.showList();
                    } else {
                        this.disabled = false;
                        this.$notification.error(response.data.message, {
                            timer: 3,
                            position: "bottomCenter",
                        });
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.disabled = false;
                    console.log("Subject Assign Update API Error", error.errors);
                });
        },

        showList() {
            this.$router.push({
                path: "/subjectTeacher/list"
            });
        },
    },
    mounted() {
        this.isLoading = true;
        this.getAllTeacher();
        this.getCampusGradeName();
        this.getSubjectTeacherData();
    },
};