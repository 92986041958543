import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import CONFIG from "@/assets/js/config.js";
import store from '../../store';

export default {
    name: "CheckoutList",
    components: {
        MainLayout,
        flatPickr,
        Select2Multiple,
        AdvancedLaravelVuePaginate,
        Loading,
    },

    data() {
        return {
            checkoutData: {},
            searchData: {
                name: '',
                start_date: '',
                to_date: '',
                status: null,
                qr_code_no: '',
            },
            returnStatusArr: [
                { id: 0, text: 'Select Status' },
                { id: 1, text: 'Pending' },
                { id: 2, text: 'Complete' },
                { id: 3, text: 'Cancel' },
            ],
            selectedStatus: 0,
            selectedCategory: 0,
            selectedType: 0,
            pending_status: 1,
            complete_status: 2,
            cancel_status: 3,
            noDataMsg: CONFIG.NO_DATA_FOUND,
            isLoading: false,
            search_status: false,
            searchBtnStatus: false,
            campusId: '',
        };
    },

    methods: {
        truncateBooks(books) {
            if (books.length > 100) {
                return books.substring(0, 100) + '...';
            }
            return books;
        },

        getCheckoutList(page = 1) {
            var request = {
                params: {
                    campus_id: store.state.user.campus_id
                }
            }
            axios.get("/checkout/list?page=" + page, request)
                .then(response => {
                    this.search_status = false;
                    this.checkoutData = response.data;
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Checkout List API Error", error);
                });
        },

        reset() {
            this.searchData.name = ''
            this.searchData.start_date = ''
            this.searchData.to_date = ''
            this.searchData.return_date = ''
            this.searchData.qr_code_no = ''
            this.searchData.status = null
        },

        searchCheckout(page = 1) {
            this.isLoading = true;
            var request = {
                params: {
                    name: this.searchData.name,
                    start_date: this.searchData.start_date,
                    to_date: this.searchData.to_date,
                    qr_code_no: this.searchData.qr_code_no,
                    status: this.searchData.status,
                    campus_id: this.$store.state.user.campus_id
                }
            }

            axios.get("/checkout/search?page=" + page, request)
                .then(response => {
                    this.search_status = true;
                    this.checkoutData = response.data;
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Checkout Search API Error", error);
                });
        },

        confirmDelete(id) {
            if (window.confirm("Are you sure you want to delete?")) {
                this.delete(id);
            }
        },

        confirmCancel(id) {
            if (window.confirm("Are you sure you want to cancel?")) {
                this.cancel(id);
            }
        },

        cancel(id) {
            axios.post("/checkout/cancel/" + id)
                .then(res => {
                    if (res.data.success == true) {
                        this.getCheckoutList();
                        this.$notification.success("Checkout Data Cancelled Successfully.", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                    } else {
                        if (res.data.success == false) {
                            this.$notification.error("Checkout data is unavailable to cancel.", {
                                timer: 3,
                                position: "bottomCenter"
                            });
                        }
                    }
                    this.isLoading = true;
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                })
        },

        delete(id) {
            axios.delete("/checkout/delete/" + id)
                .then(res => {
                    if (res.data.success == true) {
                        this.getCheckoutList();
                        this.$notification.success("Checkout Data Deleted Successfully.", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                    } else {
                        if (res.data.success == false) {
                            this.$notification.error("Checkout data is unavailable to delete.", {
                                timer: 3,
                                position: "bottomCenter"
                            });
                        }
                    }
                    this.isLoading = true;
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                })
        },

        showCreate() {
            this.$router.push({
                path: "/checkout/create"
            });
        },

        showEdit(id) {
            this.$router.push({
                name: "checkoutEdit",
                params: {
                    id: id
                }
            });
        },

        showDetail(classId) {
            this.$router.push({
                name: "checkoutDetail",
                params: {
                    id: classId
                }
            });
        }
    },

    mounted() {
        this.campusId = this.$store.state.user.campus_id;
        this.isLoading = true;
        this.getCheckoutList();
    },
};
