import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import {
  required,
  maxLength,
  minLength,
  email,
  numeric,
  not,
  sameAs
} from "vuelidate/lib/validators";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import store from "@/store";
import { ConvertNumService } from '@/assets/js/convertNumberService.js';

const convertNumberService = new ConvertNumService();

export default {
  name: "ParentEdit",
  components: {
    MainLayout,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      parentData: {},
      pwdData: {
        password:'',
        confirm_password:''
      },
      noDataMsg: CONFIG.NO_DATA_EXIST,
      submitted: false,
      isLoading: false,
      disabled: false,
      pwd_change: false,
      nrcNameJson: null,
      naingJson: null,
      nrcno: null,

      guardian1_nrc_number: "",
      guardian1NrcKey: "",
      guardian1SelectedNrcName: "",
      guardian1SelectedNrcNaing: "",
      guardian1DistrictList: [],
      guardian1NaingList: [],

      guardian2_nrc_number: "",
      guardian2NrcKey: "",
      guardian2SelectedNrcName: "",
      guardian2SelectedNrcNaing: "",
      guardian2DistrictList: [],
      guardian2NaingList: [],

      nrcName: [],
      nrcExists: null,
      phoneExists: null,
      nrc1Exists: {exists: null},
      nrc2Exists: {exists: null},
      nrcSame: null,
      parentExists: null,
    };
  },

  validations: {
    parentData: {
      guardian1_name: {
        required,
        maxLength: maxLength(50)
      },
      guardian1_job: {
        maxLength: maxLength(200)
      },
      guardian2_name: {
        maxLength: maxLength(50)
      },
      guardian2_job: {
        maxLength: maxLength(200)
      },
      guardian1_nrc_number: {
        minLength: minLength(6),
        maxLength: maxLength(6)
      },
      guardian2_nrc_number: {
        minLength: minLength(6),
        maxLength: maxLength(6)
      },
      email: {
        maxLength: maxLength(50),
        email
      },
      phone1: {
        required,
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(11)
      },
      phone2: {
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(11),
        notSameAs: not(sameAs('phone1'))
      },
      address: {
        required,
        maxLength: maxLength(1000)
      },
    }
  },

  methods: {
    async getNrcCity() {
      try {
        const response = await axios.get('getNrcCity')
        if (response) {
          this.nrcno = response.data;
        }
      } catch (error) {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      }
    },

    async getNrcNaing() {
      try {
      const response = await axios.get('getNrcNaing')
        if (response) {
          this.naingJson = response.data;
        }
      } catch(error) {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        }
    },

    async getNrcTownship() {
      try {
      const response = await axios.get('getNrcTownship')
        if (response) {
          return response.data;
        }
      }catch(error) {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        }
    },

    async changeNrcName(value) {
        this.nrcNameJson = await this.getNrcTownship();
        this.nrcName = this.nrcNameJson[value];
        return this.nrcName;
    },
    
    async nrcOnChange(event, option) {
      var optionText = event.target.value;
      var convertNumberService = new ConvertNumService();
      var optionValue = convertNumberService.convertMm2Eng(optionText);

      if (optionValue) {
          this.$set(this, option + 'NrcKey', optionText)
      } else {
          this.$set(this, option + 'NrcKey', optionValue)
      }
      this.$set(this, option + 'SelectedNrcName', "");
      this.$set(this, option + 'DistrictList', await this.changeNrcName(optionValue));
    },

    onChangeNrcName(event, op) {
      var optionValue = event.target.value;
      if(!optionValue){
        if (op == 'guardian1') {
          this.guardian1SelectedNrcNaing = "";
          this.parentData.guardian1_nrc_number = "";
        } else {
          this.guardian2SelectedNrcNaing = ""
          this.parentData.guardian2_nrc_number = ""
        }
      }
      op == 'guardian1' ? this.guardian1NaingList = this.naingJson : this.guardian2NaingList = this.naingJson;
    },

    changePassword() {
      this.pwd_change = true;
    },

    async getParentEdit() {
      try {
      const response = await axios.get("/parent/detail/" + this.$route.params.id)
          if (response.data) {
            this.parentData = response.data[0];
  
            if (this.parentData.guardian1_nrc_number) {
                var nrcfirst = this.parentData.guardian1_nrc_number.split('/');
                var nrcsecond = nrcfirst[1].split('(');
                var nrcthird = nrcsecond[1].split(')');
    
                var convertNumberService = new ConvertNumService();
                var nrckeyEng = convertNumberService.convertMm2Eng(nrcfirst[0]);
                this.guardian1NrcKey = nrcfirst[0]; 
    
                this.guardian1SelectedNrcName = nrcsecond[0];
                const result = await this.changeNrcName(nrckeyEng);
                this.guardian1DistrictList = result;
                this.guardian1NaingList = this.naingJson;
                this.guardian1SelectedNrcNaing = '(' + nrcthird[0] + ')';
                this.parentData.guardian1_nrc_number = nrcthird[1];
            }
            if (this.parentData.guardian2_nrc_number) {
                var mnrcfirst = this.parentData.guardian2_nrc_number.split('/');
                var mnrcsecond = mnrcfirst[1].split('(');
                var mnrcthird = mnrcsecond[1].split(')');
    
                var mnrckeyEng = convertNumberService.convertMm2Eng(mnrcfirst[0]);
                this.guardian2NrcKey = mnrcfirst[0]; 
    
                this.guardian2SelectedNrcName = mnrcsecond[0];
                this.guardian2DistrictList = await this.changeNrcName(mnrckeyEng);
      
                this.guardian2NaingList = this.naingJson;
                this.guardian2SelectedNrcNaing = '(' + mnrcthird[0] + ')';
                this.parentData.guardian2_nrc_number = mnrcthird[1];
            }
          }
          this.isLoading = false;
        }
        catch(error) {
          this.isLoading = false;
          console.log("Parent Detail API Error", error);
        }
    },

    mergeNRC(op) {
      if(op == 'guardian1') {
        return convertNumberService.convertEng2Mm(
            this.guardian1NrcKey +
            "/" +
            this.guardian1SelectedNrcName +
            this.guardian1SelectedNrcNaing +
            this.parentData.guardian1_nrc_number
        );
      } else {
        return convertNumberService.convertEng2Mm(
          this.guardian2NrcKey +
          "/" +
          this.guardian2SelectedNrcName +
          this.guardian2SelectedNrcNaing +
          this.parentData.guardian2_nrc_number
      );
      }
    },

    async checkDuplicateNrc(nrcNumber, guardianNo, updateVar) {
      try {
        const response = await axios.get("parent/checkDuplicateNrc", {
          params: {
            nrc_number: nrcNumber,
            id: this.parentData.id,
          },
        });
        updateVar.exists = response.data.exists;
        if (updateVar.exists) {
          this.$notification.error(guardianNo + " Duplicate NRC exists.", {
            timer: 3,
            position: "bottomCenter",
          });
        }
        this.disabled = false;
      } catch (error) {
        console.error('An error occurred:', error);
      }
    },

    async checkDuplicateParentByNameAndPh() {
      try {
        const response = await axios.get("parent/checkDuplicateParentByNameAndPh", {
          params: {
            guardian1_name: this.parentData.guardian1_name,
            phone1: this.parentData.phone1,
            parent_id: this.parentData.id
          },
        });
        this.parentExists = response.data.exists;
        if (this.parentExists) {
          this.$notification.error("Duplicate Parent With Guardian 1 Name and Phone 1 Exists.", {
            timer: 3,
            position: "bottomCenter",
          });
        }
        this.disabled = false;
      } catch (error) {
        console.error('An error occurred:', error);
      }
    },

    async confirmUpdate() {
      this.submitted = true;

      var guardian1NrcNumber = this.guardian1NrcKey ? this.mergeNRC('guardian1') : null;
      var guardian2NrcNumber = this.guardian2NrcKey ? this.mergeNRC('guardian2') : null;
      if ((guardian1NrcNumber !== null && guardian2NrcNumber !== null) && (guardian1NrcNumber == guardian2NrcNumber)) {
        this.nrcSame = true;
        this.$notification.error("Guardian 1's NRC cannot be the same as Guardian 2's NRC.", {
          timer: 3,
          position: "bottomCenter",
        });
      } else {
        if (guardian1NrcNumber != null) {
          await this.checkDuplicateNrc(guardian1NrcNumber, "Guardian 1", this.nrc1Exists);
        }
        if (guardian2NrcNumber != null) {
          await this.checkDuplicateNrc(guardian2NrcNumber, "Guardian 2", this.nrc2Exists);
        }
      }

      if (this.parentData.phone1 && this.parentData.guardian1_name) {
        await this.checkDuplicateParentByNameAndPh();
      }

      if (this.$v.parentData.$invalid ||
        this.nrcSame || this.nrc1Exists.exists || this.nrc2Exists.exists || this.parentExists) {
            this.nrcSame = null;
        return;
      } else if(this.pwd_change) {
        if(this.pwdData.password == '' || this.pwdData.password == undefined || this.pwdData.confirm_password == '' || this.pwdData.confirm_password == undefined) {
          return;
        } else if (this.pwdData.password.length < 8 || this.pwdData.password.length >20 || this.pwdData.confirm_password.length < 8 || this.pwdData.confirm_password.length >20) {
          return;
        } else if(this.pwdData.password != this.pwdData.confirm_password) {
          this.$notification.error("Password and confirm password do not match.", {
            timer: 3,
            position: "bottomCenter",
          });
          return;
        }
      }
      this.update();
    },

    update() {
      this.disabled = true;
      var request = {
        guardian1_name: this.parentData.guardian1_name,
        guardian1_job: this.parentData.guardian1_job,
        guardian2_name: this.parentData.guardian2_name,
        guardian2_job: this.parentData.guardian2_job,
        password: this.pwdData.password,
        guardian1_nrc_number: this.guardian1NrcKey ? this.mergeNRC('guardian1') : '',
        guardian2_nrc_number: this.guardian2NrcKey ? this.mergeNRC('guardian2') : '',
        email: this.parentData.email,
        phone1: this.parentData.phone1,
        phone2: this.parentData.phone2,
        address: this.parentData.address,
        updated_id: store.state.user.id
      }

      axios.post("/parent/update/" + this.$route.params.id, request, {
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(response => {
          if (response.data.success == true) {
            this.$notification.success("Parent Updated Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: "parentList"
            });
          } else {
            this.disabled = false;
            this.$notification.error(response.data.message, {
              timer: 3,
              position: "bottomCenter"
            });
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          this.disabled = false;
          console.log("Parent Update API Error", error.errors);
        });
    },

    showList() {
      this.$router.push({
        path: "/parent/list"
      });
    },

    back() {
      this.$router.go(-1);
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getNrcCity();
    await this.getNrcNaing();
    await this.getNrcTownship();
    this.getParentEdit();
  },
};
