import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
import {
  required,
  maxLength,
  email
} from "vuelidate/lib/validators";
import store from '@/store'

export default {
  name: "adminEdit",
  components: {
    MainLayout,
    Select2Multiple,
    Loading,
  },

  data() {
    return {
      admin: [],
      adminRole: null,
      selected_value: 0,
      submitted: false,
      isLoading: false,
      emailExists: null,
      roleNameErr: false,
      campusId: store.state.user.campus_master.campus_code,
      disabled: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      config: CONFIG,
    };
  },

  validations: {
    admin: {
      admin_campus_id: {
        required
      },
      name: {
        required,
        maxLength: maxLength(100)
      },
      email: {
        maxLength: maxLength(255),
        email
      },
      role: {
        required
      },
      role_name: {
        maxLength: maxLength(100)
      },
      status: {
        required
      }
    },
  },

  methods: {
    async checkDuplicateEmail() {
      try {
          const response = await axios.get("checkDuplicateEmail", {
              params: {
                  model: 'Admin',
                  email: this.admin.email,
              },
          });
          this.emailExists = response.data.exists;
          if(this.emailExists) {
            this.$notification.error("Email already existed.", {
              timer: 3,
              position: "bottomCenter",
            });
          }
          this.disabled = false;
      } catch (error) {
          console.error('An error occurred:', error);
      }
    },

    isRoleOther() {
      return this.admin.role == CONFIG.ROLE.OTHER;
    },

    getAdminRole() {
      axios.get('getAdminRole')
        .then(res => {
          this.adminRole = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getDetail() {
      var request = {
        params: {
          campus_id: this.$store.state.user.campus_id
        }
      }
      axios.get(`admin/detail/${this.$route.params.id}`, request)
        .then(res => {
          if (res.data) {
            this.admin = res.data;
          }
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Admin Detail API Error", error);
      })
    },

    async update() {
      this.submitted = true;
      if (this.admin.mail) {
        await this.checkDuplicateEmail();
      }
      if (this.isRoleOther() && !this.admin.role_name) {
        this.roleNameErr = true;
      } else {
        this.roleNameErr = false;
      }
      if (this.$v.$invalid || this.admin.role == 0 || this.admin.role == '' || this.emailExists || this.roleNameErr) {
        return;
      }
      this.admin.login_id = this.$store.state.user.id;
      this.admin.campus_id = this.$store.state.user.campus_id;
      this.disabled = true;
      axios.post(`admin/update/${this.$route.params.id}`, this.admin)
      .then(res => {
        if (res.data.success == true) {
          this.$notification.success("Admin Updated Successfully", {
            timer: 3,
            position: "bottomCenter"
          });
          this.$router.push({
            name: "adminList"
          });
        } else {
          this.disabled = false;
          this.$notification.error(res.data.message, {
            timer: 3,
            position: "bottomCenter",
          });
        }
      })
      .catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      })
    }
  },

  mounted() {
    this.getAdminRole();
    this.isLoading = true;
    this.getDetail();
  },
};
