import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import {
  required,
  maxLength
} from "vuelidate/lib/validators";
import axios from 'axios';

export default {
  name: "DailyFerryCreate",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
  },

  data() {
    return {
      DFData: {
        driver_id: null,
        ferry_id: null,
        route_id: null,
        from_date: '',
        to_date: '',
        ferry_pickup_id: [],
      },
      selectedPickups: [],
      driverArr: [],
      total_seat: null,
      pickupData: null,
      ferryArr: [],
      routeArr: [],
      submitted: false,
      disabled: false,
      pointError: false,
      dateError: false,
    };
  },

  validations: {
    DFData: {
      driver_id: {
        required
      }, 
      ferry_id: {
        required
      }, 
      route_id: {
        required
      },
      from_date: {
        required,
        maxLength: maxLength(20)
      },
      to_date: {
        required,
        maxLength: maxLength(20)
      }
    },
  },

  methods: {
    getDriver() {
      axios.get('driver/getDriverForDailyFerry', {
        params: {
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then(res => {
          this.driverArr = res.data.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getFerry() {
      axios.get('ferry/getFerryForDailyFerry', {
        params: {
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then(res => {
          this.ferryArr = res.data.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getTotalSeat(ferry_id) {
      this.total_seat = null;
      axios.get(`ferry/getTotalSeat/${ferry_id}`, {
        params: {
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then(res => {
          this.total_seat = res.data[0].total_seats;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getRoute() {
      axios.get('route/getRouteForRoutePoint', {
        params: {
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then(res => {
          this.routeArr = res.data.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getPickup(route_id) {
      this.selectedPickups = [];
      this.pickupData = null;
      axios.get(`dailyFerry/getPickup/${route_id}`, {
        params: {
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then(res => {
          this.pickupData =res.data.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    CheckTotal(index) {
      if (this.total_seat != null) {
        if (this.total_seat < this.selectedPickups.length) {
          alert("This is over weight. Please remove some people from this route");
          document.getElementById(index).checked = false;
          this.selectedPickups.splice(-1, 1);
        }
      }
    },

    create() {
      this.submitted = true;
      if (this.DFData.from_date && this.DFData.to_date && new Date(this.DFData.to_date) < new Date(this.DFData.from_date)) {
        this.dateError = true;
      } else {
        this.dateError = false;
      }
      if ((this.pickupData != null && this.selectedPickups.length == 0) || this.selectedPickups.length == 0) {
        this.pointError = true;
      } else {
        this.pointError = false;
      }
      if (this.$v.$invalid || this.pointError || this.dateError) {
        return;
      }
      this.DFData.loginId = this.$store.state.user.id;
      this.DFData.ferry_pickup_id = this.selectedPickups;
      this.DFData.campus_id = this.$store.state.user.campus_id;
      this.disabled = true;
      axios.post('dailyFerry/create', this.DFData)
        .then(res => {
          if (res.data.success == true) {
            this.$notification.success("Daily Ferry Added Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({ name: 'dailyFerryList' })
          } else {
            this.disabled = false;
            this.$notification.error(res.data.message, {
              timer: 3,
              position: "bottomCenter"
            });
          }
          this.disabled = false;

        }).catch(error => {
          this.disabled = false;
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.getRoute();
    this.getDriver();
    this.getFerry();
  }
};
