import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Select2Multiple from "v-select2-multiple-component";
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { required, maxLength, minLength, numeric } from "vuelidate/lib/validators";
import CONFIG from "@/assets/js/config.js";
import { ConvertNumService } from '@/assets/js/convertNumberService.js';

const convertNumberService = new ConvertNumService();

export default {
  name: "DriverEdit",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    Loading,
  },

  data() {
    return {
      imageLargeMsg: CONFIG.IMAGE_TOO_LARGE,
      config: {
        maxDate: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + (new Date().getDate() - 1)
      },
      driverData: [],
      driver_img: '',
      password: '',
      confirm_password: '',
      pwd_change: false,
      isLoading: false,
      submitted: false,
      disabled: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      teacherImage: "",
      teacherOldImage: "",
      nrcNameJson: null,
      naingJson: null,
      nrcno: null,
      nrc_number: "",
      nrc: "",
      nrckey: "",
      selectedNrcName: "",
      selectedNrcNaing: "",
      districtList: [],
      naingList: [],
      nrcName: [],
    };
  },

  validations: {
    driverData: {
      driver_name: {
        required,
        maxLength: maxLength(100)
      },
      dob: {
        required,
        maxLength: maxLength(100),
      },
      status: {
        required
      },
      father_name: {
        required,
        maxLength: maxLength(100)
      },
      phone1: {
        required,
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(11)
      },
      phone2: {
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(11)
      },
      contact_address: {
        required,
        maxLength: maxLength(1000)
      },
      work_experience: {
        maxLength: maxLength(1000)
      },
      join_date: {
        required,
      },
    },
    nrc_number: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(6)
    }
  },

  methods: {
    async getNrcCity() {
      try {
        const response = await axios.get('getNrcCity')
        if (response) {
          this.nrcno = response.data;
        }
      } catch (error) {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      }
    },

    async getNrcNaing() {
      try {
        const response = await axios.get('getNrcNaing')
        if (response) {
          this.naingJson = response.data;
        }
      } catch (error) {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      }
    },

    async getNrcTownship() {
      try {
        const response = await axios.get('getNrcTownship')
        if (response) {
          this.nrcNameJson = response.data;
        }
      } catch (error) {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      }
    },

    getDetail() {
      axios.get(`driver/detail/${this.$route.params.id}`, { params: { campus_id: this.$store.state.user.campus_id }})
        .then((res) => {
          if (res.data) {
            this.driverData = res.data.data;
            this.driver_img = this.driverData.img_path;

            var nrcfirst = this.driverData.nrc_number.split('/');
            var nrcsecond = nrcfirst[1].split('(');
            var nrcthird = nrcsecond[1].split(')');
            var convertNumberService = new ConvertNumService();
            var nrckeyEng = convertNumberService.convertMm2Eng(nrcfirst[0]);
            this.nrckey = nrcfirst[0]; 

            this.selectedNrcName = nrcsecond[0];
            this.districtList = this.changeNrcName(nrckeyEng);
            this.naingList = this.naingJson;
            this.selectedNrcNaing = '(' + nrcthird[0] + ')';
            this.nrc_number = nrcthird[1];
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);
    },

    onChange(event) {
      var optionText = event.target.value;
      var convertNumberService = new ConvertNumService();
      var optionValue = convertNumberService.convertMm2Eng(optionText);
      if (optionValue) {
        this.nrckey = optionText;
      } else {
        this.nrckey = optionValue;
      }
      this.selectedNrcName = "";
      this.districtList = this.changeNrcName(optionValue);
    },

    changeNrcName(value, nrcName) {
      nrcName = this.nrcNameJson[value];
      return nrcName;
    },

    onChangeNrcName(event) {
      var optionValue = event.target.value;
      if (!optionValue) {
        this.selectedNrcNaing = "";
        this.nrc_number = "";
      }
      this.naingList = this.naingJson;
    },

    checkNRC() {
      if (this.nrckey !== undefined && this.selectedNrcName !== undefined &&
        this.selectedNrcNaing !== undefined && this.driverData.nrc_number !== "") {
        return true;
      } else if (this.nrckey == undefined && this.selectedNrcName == undefined &&
        this.selectedNrcNaing == undefined && this.driverData.nrc_number == "") {
        return true;
      } else return false;
    },

    mergeNRC() {
      return convertNumberService.convertEng2Mm(this.nrckey + "/" + this.selectedNrcName + this.selectedNrcNaing + this.nrc_number);
    },

    chooseFiles(id) {
      document.getElementById(id).click();
    },

    uploadImage(id) {
      const inputfile = document.getElementById(id).files[0];
      if (inputfile) {
        if (inputfile.type.includes("image/")) {
          if (inputfile.type == "image/gif") {
            this.$notification.error("File not supported!", {
              timer: 3,
              position: "bottomCenter",
            });
            return null;
          }
          let reader = new FileReader();
          reader.readAsDataURL(inputfile);
          reader.onloadend = () => {
            if (reader.result.length > 5242880) {
              this.$notification.error(this.imageLargeMsg, {
                timer: 3,
                position: "bottomCenter",
              });
            } else {
              this.$set(this, id, reader.result);
            }
          };
        } else {
          this.$notification.error("File not supported!", {
            timer: 3,
            position: "bottomCenter",
          });
          return null;
        }
      }
    },

    removeImg(img) {
      this.$set(this, img, '');
      document.getElementById(img).value = '';
    },

    changePassword() {
      this.pwd_change = true;
    },

    update() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      } 
      else if (this.pwd_change) {
        if (this.password == '' || this.password == undefined || this.confirm_password == '' || this.confirm_password == undefined) {
          return;
        } else if (this.password.length < 8 || this.password.length > 20 || this.confirm_password.length < 8 || this.confirm_password.length > 20) {
          return;
        } else if (this.password != this.confirm_password) {
          this.$notification.error("Password and confirm password do not match.", {
            timer: 3,
            position: "bottomCenter",
          });
          return;
        }
      }

      this.disabled = true;
      this.driverData.loginId = this.$store.state.user.id;
      this.driverData.driverImg = this.driver_img;
      this.driverData.oldImg = this.driverData.driver_img;
      this.driverData.nrc_number = this.mergeNRC();
      this.driverData.password = this.password;
      this.driverData.pwd_change = this.pwd_change;

      axios.post(`driver/update/${this.$route.params.id}`, this.driverData)
        .then(res => {
          if (res.data.success) {
            this.$notification.success("Driver Updated Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: "driverList"
            });
          } else {
            this.disabled = false;
            this.$notification.error(res.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.disabled = false;
          this.isLoading = false;
        });
    }
  },

  async mounted() {
    this.isLoading = true;
    await this.getNrcCity();
    await this.getNrcNaing();
    await this.getNrcTownship();
    this.getDetail();
  },
}
