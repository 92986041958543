import { render, staticRenderFns } from "./categoryItems.vue?vue&type=template&id=d63a31bc"
import script from "../../controllers/library/category-items.js?vue&type=script&lang=js&external"
export * from "../../controllers/library/category-items.js?vue&type=script&lang=js&external"
import style0 from "./categoryItems.vue?vue&type=style&index=0&id=d63a31bc&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports