import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Select2Multiple from 'v-select2-multiple-component';
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import upLoad from '@/assets/img/upload_photo3.jpg';
import store from "@/store";
import {
  required,
  maxLength
} from "vuelidate/lib/validators";

export default {
  name: "noticeEdit",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      config : { 
        minDate: new Date().getFullYear() + '-' + (new Date().getMonth() + 1)+ '-' + (new Date().getDate() )
      },
      time_config: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
      },
      noticeData: {
        send_to: 0,
        class: [],
        student_id: [],
        teacher_id: [],
      },
      allTea: [],
      allStu: [],
      classArr: [],
      submitted: false,
      studentVali: false,
      campusId: '',
      classIdForStu: null,
      disabled: false,
      isLoading:false,
      classZeroValidation: false,
      errors: '',
      classVali: false,
      teacherVali: false,
      classForStu: false,
      classArrForStudent: [],
      defaultSelectOption:  [{
                              'id': 0,
                              'text': 'All'
                            }],
      teacherZeroValidation: false,
      studentZeroValidation: false,
      images: [],
      upLoad,
      noticeArr: null,
      existingImages: [],
      sendDateErr: false,
    };
  },

  validations: {
    noticeData: {
      title: {
        required,
        maxLength: maxLength(500)
      },
      message: {
        required,
        maxLength: maxLength(5000)
      },
      send_date: {
        required,

      },
      send_time: {
        required
      },

    }
  },

  methods: {
    changeEvent(val, fieldName) {
      if (fieldName == 'studentVali' && val != null) {
        this.studentVali = false;
      }
      if (fieldName == 'teacherVali' && val != null) {
        this.teacherVali = false;
      }
      if (fieldName == 'classVali' && val != null) {
        this.classVali = false;
      }
    },

    getDetail() {
        axios.get('notice/detail/' + this.$route.params.id, {
            params: {
                campus_id: store.state.user.campus_id
            }
        })
        .then((res) => {
            if (res.data) {
                this.noticeData = res.data;
                this.class_id = this.noticeData.class_id;
                this.teacher_id = this.noticeData.teacher_id;
                this.student_id = this.noticeData.student_id;
                if(this.noticeData.notice_images){
                    this.existingImages = this.noticeData.notice_images
                }
            }
            this.isLoading = false;
        })
        .catch((error) => {
            if (error.response.status == 401) {
                this.$store.commit('logout');
                window.location.replace('login');
            }
            this.isLoading = false;
        });
    },

    update() {
        this.submitted = true;
        if (this.$v.$invalid || this.noticeData.class_id == 0 || this.noticeData.subject_id == 0 || this.noticeData.subject_id == '') {
            return;
        }
        this.noticeData.img_paths = this.existingImages;
        this.noticeData.new_images = this.images;
        this.noticeData.updated_id = this.$store.state.user.id;
        this.noticeData.campus_id = this.$store.state.user.campus_id;
        this.disabled = true;
        axios.post(`notice/update/${this.noticeData.id}`, this.noticeData)
            .then(res => {
                if (res.data) {
                    this.$notification.success("Notice Updated Successfully", {
                        timer: 3,
                        position: "bottomCenter"
                    });
                    this.$router.push({
                        name: "noticeList"
                    });
                }
            }).catch(responseObj => {
                if (('send_date'  in responseObj.response.data.errors)) {
                  document.getElementById('sendDate').innerHTML = responseObj.response.data.errors['send_date'][0];
                  document.getElementById('sendTime').innerHTML = responseObj.response.data.errors['send_date'][0];
                  this.sendDateErr = true;
                }
                if (responseObj.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                this.isLoading = false;
                this.disabled = false;
            });
    },

    chooseFiles(id) {
        document.getElementById(id).click();
    },

    onFileChange(e) {
        var selectedFiles = e.target.files;
        if (selectedFiles[0].type.startsWith("image/")) {
            if (selectedFiles.type == "image/gif") {
                this.$notification.error("File not supported!", {
                    timer: 3,
                    position: "bottomCenter",
                });
                return;
            }
            for (let i = 0; i < selectedFiles.length; i++) {
                if (selectedFiles[i].size > CONFIG.IMAGE_MAX_FILE_SIZE) {
                    this.$notification.error(CONFIG.IMAGE_MAX_SIZE_ERROR, {
                        timer: 3,
                        position: "bottomCenter",
                    });
                    continue;
                }
                if (this.images.length > 4) {
                    this.$notification.error("Can only upload a maximum of five images.", {
                        timer: 3,
                        position: "bottomCenter",
                    });
                } else {
                    this.images.push(selectedFiles[i]);
                    for (let i = 0; i < this.images.length; i++) {
                        let reader = new FileReader();
                        reader.addEventListener('load', ((index) => {
                            return () => {
                                let refName = this.images[index].name;
                                if (this.$refs[refName] && this.$refs[refName][0]) {
                                    this.$refs[refName][0].src = reader.result;
                                }
                                this.images[index].data = reader.result;
                            };
                        })(i), false);

                        reader.readAsDataURL(this.images[i]);
                    }
                }
            }
        } else {
            this.$notification.error("File not supported!", {
                timer: 3,
                position: "bottomCenter",
            });
            return null;
        }
    },

    removeImage(i) {
        this.images.splice(i, 1);
    },

    removeExistingImage(id) {
        const indexInExistingImages = this.existingImages.findIndex(img => img.id === id);
        this.existingImages.splice(indexInExistingImages, 1);
    },

    getNotice() {
      axios.get('getNotice')
        .then(res => {
          this.noticeArr = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
  },

  mounted() {
    this.campusId = this.$store.state.user.campus_id;
    this.getDetail();
    this.getNotice();
  },
};
