import { render, staticRenderFns } from "./StudentProfile.vue?vue&type=template&id=74db0380"
import script from "../../controllers/student/student-profile.js?vue&type=script&lang=js&external"
export * from "../../controllers/student/student-profile.js?vue&type=script&lang=js&external"
import style0 from "./StudentProfile.vue?vue&type=style&index=0&id=74db0380&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports