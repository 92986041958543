import { render, staticRenderFns } from "./DailyFerryEdit.vue?vue&type=template&id=1a101860"
import script from "../../controllers/daily_ferry/daily-ferry-edit.js?vue&type=script&lang=js&external"
export * from "../../controllers/daily_ferry/daily-ferry-edit.js?vue&type=script&lang=js&external"
import style0 from "./DailyFerryEdit.vue?vue&type=style&index=0&id=1a101860&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports