import MainLayout from "@/components/layout/admin/MainLayout.vue";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
import {
  required,
  maxLength
} from "vuelidate/lib/validators";

export default {
  name: "adminEdit",
  components: {
    MainLayout,
    flatPickr,
    Loading,
  },

  data() {
    return {
      periodData: [],
      submitted: false,
      isLoading: false,
      disabled: false,
      isError: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
    };
  },

  validations: {
    periodData: {
      period_name: {
        required,
        maxLength: maxLength(100)
      },
      start_date: {
        required
      },
      end_date: {
        required
      }
    },
  },

  methods: {
    getDetail() {
      axios.get(`period/detail/${this.$route.params.id}`)
        .then(res => {
          if (res.data) {
            this.periodData = res.data;
          }
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        })
    },

    update() {
      this.submitted = true;
      if (this.periodData.start_date && this.periodData.end_date && new Date(this.periodData.end_date) <= new Date(this.periodData.start_date)) {
        this.isError = true;
      } else {
        this.isError = false;
      }

      if (this.$v.$invalid || this.isError) {
        return;
      }
      
      this.disabled = true;
      this.periodData.login_id = this.$store.state.user.id;
      axios.post(`period/update/${this.$route.params.id}`, this.periodData)
        .then(res => {
          if (res.data.success == true) {
            this.$notification.success("Academic Period Updated Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: "periodList"
            });
          } else {
            this.disabled = false;
            this.$notification.error(res.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
        }).catch(error => {
          this.disabled = false;
          this.isLoading = false;
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        })
    }
  },

  mounted() {
    this.isLoading = true;
    this.getDetail();
  },
};
