import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Select2Multiple from 'v-select2-multiple-component';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import axios from "axios";
import store from '../../store';
import CONFIG from "@/assets/js/config.js";
import {
    required,maxLength
} from "vuelidate/lib/validators";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: "gradingEdit",
    components: {
        MainLayout,
        Select2Multiple,
        flatPickr,
        Loading
    },

    data() {
        return {
            isLoading: false,
            noDataMsg: CONFIG.NO_DATA_EXIST,
            classData: {},
            remark: '',
            selectedClass: 0,
            selectedYear: 0,
            classArr: [],
            subjectArr: [],
            studentNameArr: [],
            examNameArr: [],
            academic_year: '',
            academicYear: '',
            exam_id: '',
            class_id: '',
            gradingSelected: 0,
            student_id: 0,
            grading_id: 0,
            subject_id: -1,
            academicYearArr: null,
            gradingArr: null,
            submitted: false,
            gradingInfo: {},
            gradeData: {},
            firstname_ErrMsg: [],
            msgCount: 0,
            comment_msg: [],
            cmt: 0,
            cmtCount: 0,
            subject_ErrMsg: '',
            items: [],
            comment: [],
            secondname_ErrMsg: [],
            secondcomment_msg: [],
            second_cmt: 0,
            isError: false,
        };
    },

    validations: {
        class_id: {
            required
        },
        academic_year: {
            required
        },
        student_id: {
            required
        },
        subject_id: {
            required
        },
        exam_id: {
            required
        },
        remark: {
            maxLength: maxLength(1000)
        },
    },

    methods: {

        getAcademicYear() {
            axios.get('getAcademicYear', {
              params: {
                  campus_id: store.state.user.campus_id
              }
          })
                .then(res => {
                let $defaultSelectOption = [{
                    'id': 0,
                    'text': 'Select'
                }];
                this.academicYearArr = $defaultSelectOption.concat(res.data.academicYearArr);
                }).catch((error) => {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                this.isLoading = false;
                });
        },
        
        changeEvent(val, fieldName) {
            this.submitted = false;
            this.$set(this, fieldName, val);
            if (fieldName == "academic_year") {
                this.isLoading = true;
                this.class_id = 0;
                this.selectedClass = 0;
                this.student_id = '';
                this.subject_ErrMsg = '';
                this.studentNameArr = [];
                this.firstname_ErrMsg = [];
                this.examNameArr = [];
                this.comment_msg = [];
                this.classArr = [];
                this.gradeData.forEach((value, index) => {
                    this.gradeData[index]['grading'] = 0;
                    this.gradeData[index]['grading_comment'] = '';
                });
                this.items = [];
                this.comment = [];
                this.secondname_ErrMsg = [];
                this.secondcomment_msg = [];
                this.getClass(val);
            }
            if (fieldName == "class_id") {
                this.isLoading = true;
                this.subject_id = -1;
                this.student_id = '';
                this.subject_ErrMsg = '';
                this.studentNameArr = [];
                this.firstname_ErrMsg = [];
                this.comment_msg = [];
                this.subjectArr = [];
                this.gradeData.forEach((value, index) => {
                    this.gradeData[index]['grading'] = 0;
                    this.gradeData[index]['grading_comment'] = '';
                });
                this.items = [];
                this.comment = [];
                this.secondname_ErrMsg = [];
                this.secondcomment_msg = [];
                this.getStudentName(val);
                this.getSubjectByClassId(val);
                this.getExamforClass(val);
            }
        },

        changeEventSubject(val, index) {
            if (val === null || val === '') {
                this.firstname_ErrMsg[index] = 'Please Enter Grading Mark.';
            } else {
                this.firstname_ErrMsg[index] = '';
            }
        },

        changeEventSubjectForItem(val, index) {
            if (val === null || val === '') {
                this.secondname_ErrMsg[index] = 'Please Enter Grading Mark.';
            } else {
                this.secondname_ErrMsg[index] = '';
            }
        },

        async getGradingDetail() {
            try {
                const response = await axios.get("/grading/detail/" + this.$route.params.student_id, {
                    params: {
                        campus_id: store.state.user.campus_id,
                        exam_id: this.$route.params.exam_id
                    }
                })
                if (response.data[0] != undefined) {
                    this.gradingInfo = response.data[0];
                    this.gradeData = response.data;
                    this.selectedYear = this.gradingInfo.academic_year;
                    this.student_id = this.gradingInfo.student_id;
                    this.exam_id = this.gradingInfo.exam_id;
                    this.subject_id = this.gradingInfo.subject_id;
                    this.selectedClass = this.gradingInfo.class_id;
                    this.class_id = this.gradingInfo.class_id;
                    this.academic_year = this.gradingInfo.academic_year;
                    this.gradingSelected = this.gradingInfo.grading;
                    await this.getClass(this.selectedYear);
                    await this.getSubjectByClassId(this.selectedClass);
                    await this.getStudentName(this.selectedClass);
                    await this.getExamforClass(this.selectedClass);
                    this.isLoading = false;
                }
            } catch (error) {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                console.log("Grading Detail API Error", error);
                this.isLoading = false;
            }
        },

        async getClass(yearId) {
            try {
                const response = await axios.get("class/classList/" + yearId, {
                    params: {
                        campus_id: store.state.user.campus_id
                    }
                });
                
                if (response.data) {
                    let defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Class'
                    }];
            
                    this.classArr = defaultSelectOption.concat(response.data);
                }
            } catch (error) {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                console.error("Error fetching class list:", error);
            } finally {
                this.isLoading = false;
            }
        },
        
        async getExamforClass(class_id) {
            try {
                const res = await axios.get("exam/examList", {
                    params: {
                        class_id: class_id,
                        campus_id: store.state.user.campus_id
                    }
                })
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Exam'
                    }];
                    this.examNameArr = $defaultSelectOption.concat(res.data);
                } catch(error) {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                } finally {
                    this.isLoading = false;
                }
        },

        async getStudentName(classId) {
            try {
                const response = await axios.get("student/getStudentName/" + classId, {
                    params: {
                        campus_id: store.state.user.campus_id
                    }
                });
        
                if (response.data) {
                    let defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Student Name'
                    }];
                    this.studentNameArr = defaultSelectOption.concat(response.data);
                }
            } catch (error) {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                console.error("Error fetching student names:", error);
            } finally {
                this.isLoading = false;
            }
        },

        getThisAcademicYear() {
          axios.get('period/getThisPeriod')
            .then(res => {
              this.academicYear = res.data;
              this.academic_year = this.academicYear.id;
              this.getClass(this.academic_year);
            }).catch((error) => {
              if (error.response.status == 401) {
                this.$store.commit('logout');
                window.location.replace('login');
              }
              this.isLoading = false;
            });
        },

        async getSubjectByClassId(classId) {
            try {
                const response = await axios.get("/class/subjectByClassId/" + classId, {
                    params: {
                        campus_id: store.state.user.campus_id,
                        acd_year_id: this.academic_year
                    }
                });
                if (response.data) {
                    this.subjectArr = response.data.subjectArr[0];
                }
            } catch (error) {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                console.log("Subject API Error", error);
            } finally {
                this.isLoading = false;
            }
        },

        confirmUpdate() {
            this.msgCount = 0;
            this.cmtCount = 0;
            this.submitted = true;
            this.isLoading = true;
            if (this.$v.$invalid || this.class_id == '' || this.class_id == 0 || this.academic_year == '' || this.academic_year == 0 || this.student_id == '' || this.student_id == 0 || this.exam_id == '' || this.exam_id == 0 || this.isError) {
                if (this.gradeData.length > 0) {
                    this.gradeData.forEach((value, index) => {
                        this.cmt = this.gradeData[index]['grading_comment'] ? this.gradeData[index]['grading_comment'].length : 0;
                        if (this.cmt > 1000) {
                            this.comment_msg[index] = 'Grading Comment';
                            this.cmtCount++;
                        } else {
                            this.comment_msg[index] = '';
                        }
                        let validItemFound = false;
                        if (Array.isArray(this.gradeData) && this.gradeData.length > 0) {
                            for (let index = 0; index < this.gradeData.length; index++) {
                                const grading = this.gradeData[index]['grading'];
                                if (grading != undefined && grading != 0) {
                                    validItemFound = true;
                                    break;
                                }
                            }
                            if (!validItemFound) {
                                this.firstname_ErrMsg[index] = "At least one Subject must have a grading mark."; // Use index 0 or adjust as needed
                                this.msgCount++;
                            }
                        }                        
                         else if (this.gradeData[index]['grading'].length > 10) {
                            this.firstname_ErrMsg[index] = "Grading Mark must be at most 10 characters.";
                            this.msgCount++;
                        } else {
                            this.firstname_ErrMsg[index] = '';
                        }
                    });
                    this.subject_ErrMsg = '';
                } else {
                    this.subject_ErrMsg = "Subject Data doesn't exist according to Grade";
                }

                for (let index = this.gradeData.length; index < this.subjectArr.length; index++) {
                    this.second_cmt = this.comment[index] ? this.comment[index].length : 0;
                    if (this.second_cmt > 1000) {
                        this.secondcomment_msg[index] = 'Grading Comment';
                        this.cmtCount++;
                    } else {
                        this.secondcomment_msg[index] = '';
                    }
                    if (this.items[index] == undefined || this.items[index] == 0) {
                        this.secondname_ErrMsg[index] = 'Please Enter Grading Mark.';
                        this.msgCount++;
                    } else if (this.items[index].length > 10) {
                        this.secondname_ErrMsg[index] = "Grading Mark must be at most 10 characters.";
                        this.msgCount++;
                    } else {
                        this.secondname_ErrMsg[index] = '';
                    }                        
                }                    
            this.subject_ErrMsg = '';

                if (this.msgCount > 0 || this.cmtCount > 0 || this.subject_ErrMsg != '' || this.isError) {
                    this.isLoading = false;
                    return;
                }
                this.isLoading = false;
                return;
            } else {
                if (this.gradeData.length > 0) {
                    this.gradeData.forEach((value, index) => {
                        this.cmt = this.gradeData[index]['grading_comment'] ? this.gradeData[index]['grading_comment'].length : 0;
                        if (this.cmt > 1000) {
                            this.comment_msg[index] = 'Grading Comment';
                            this.cmtCount++;
                        } else {
                            this.comment_msg[index] = '';
                        }
                        let validItemFound = false;
                        if (Array.isArray(this.gradeData) && this.gradeData.length > 0) {
                            for (let index = 0; index < this.gradeData.length; index++) {
                                const grading = this.gradeData[index]['grading'];
                                if (grading != undefined && grading != 0) {
                                    validItemFound = true;
                                    break;
                                }
                            }
                            if (!validItemFound) {
                                this.firstname_ErrMsg[index] = "At least one Subject must have a grading mark.";
                                this.msgCount++;
                            }
                        } 
                         else if (this.gradeData[index]['grading'].length > 10) {
                            this.firstname_ErrMsg[index] = "Grading Mark must be at most 10 characters.";
                            this.msgCount++;
                        } else {
                            this.firstname_ErrMsg[index] = '';
                        }
                    });
                    this.subject_ErrMsg = "";
                } else {
                    this.subject_ErrMsg = "Subject Data doesn't exist according to Grade";
                }
                    for (let index = this.gradeData.length; index < this.subjectArr.length; index++) {
                        this.second_cmt = this.comment[index] ? this.comment[index].length : 0;
                        if (this.second_cmt > 1000) {
                            this.secondcomment_msg[index] = 'Grading Comment';
                            this.cmtCount++;
                        } else {
                            this.secondcomment_msg[index] = '';
                        }
                        if (this.items[index] == undefined || this.items[index] == 0) {
                            this.secondname_ErrMsg[index] = 'Please Enter Grading Mark.';
                            this.msgCount++;
                        } else if (this.items[index].length > 10) {
                            this.secondname_ErrMsg[index] = "Grading Mark must be at most 10 characters.";
                            this.msgCount++;
                        } else {
                            this.secondname_ErrMsg[index] = '';
                        }                        
                    }                    
                this.subject_ErrMsg = '';

                if (this.msgCount > 0 || this.cmtCount > 0) {  
                    this.isLoading = false;
                    return;
                }
            }
            this.update();
        },

        update() {
            var request = {
                academic_year: this.academic_year,
                class_id: this.class_id,
                student_id: this.student_id,
                campus_id: store.state.user.campus_id,
                created_id: store.state.user.id,
                gradeData: this.gradeData,
                exam_id: this.exam_id,
                grading: this.items,
                gradeComments: this.comment,
                old_exam_id: this.$route.params.exam_id,
                subjectsArr: this.subjectArr,
            }

            axios.post("/grading/update/" + this.$route.params.student_id, request, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then(response => {
                    if (response.data.status == 200) {
                        this.$notification.success("Grading Updated Successfully", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.$router.push({
                            name: "gradingList"
                        });
                    }
                    else if (response.data.success == false) {
                        this.isLoading = false;
                        this.$notification.error(response.data.message, {
                            timer: 3,
                            position: "bottomCenter",
                        });
                    }
                    if (response.data.status == 201) {
                        this.isLoading = false;
                        this.$notification.error("Grading Data Already Exist", {
                            timer: 3,
                            position: "bottomCenter",
                        });
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {                       
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    console.log("Grading Edit API Error", error.errors);
                    this.isLoading = false;
                });
        },

        showList() {
            this.$router.push({
                path: "/grading/list"
            });
        },
    },

    mounted() {
        this.isLoading = true;
        this.getThisAcademicYear();
        this.getAcademicYear();
        this.getGradingDetail();
    },
};
