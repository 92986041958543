import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import axios from "axios";

export default {
    name: "HolidayDetail",
    components: {
        MainLayout,
        Loading
    },

    data() {
        return {
            noDataMsg: CONFIG.NO_DATA_EXIST,
            isLoading: false,
            date: '',
            description: '',
            holidayData: {}
        };
    },

    methods: {
        getHolidayById() {
            axios.get('holiday/detail/' + this.$route.params.id)
                .then((response) => {
                    if (response.data != undefined) {
                        this.holidayData = response.data;
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                  this.isLoading = false;
                });
        },
        showList() {
            this.$router.push({
                path: "/holiday/list"
            });
        },
    },
    mounted() {
        this.isLoading = true;
        this.getHolidayById();
    },
};
