import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import { required,maxLength } from "vuelidate/lib/validators";
import Loading from 'vue-loading-overlay';
import axios from 'axios';
import store from '../../store';

export default {
  name: "attendanceTeacherList",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      isLoading: false,
      submitted: false,
      disabled: false,
      attendanceData: {},
      teacherArr: [],
      attendanceTimeArr: null,
      selectedTime: 0,
      selectedTeacher: 0,
      status:'',
      teacher:'',
      times:'',
      time:'',

      configDateTime: {
        enableTime: true,
        dateFormat: "Y-m-d H:i:S",  
        time_24hr: true,
        enableSeconds: true,
        minuteIncrement: 1,
        secondIncrement: 1,
        defaultHour: 8,
      },
    };
  },

  validations: {
    attendanceData: {
      date: {
        required
      },
      status: {
        required
      },
      remark: {
        maxLength: maxLength(1000)
      },
    }
  },

  methods: {
    getAttendanceTime() {
      axios.get('getAttendanceTime')
        .then(res => {
          this.attendanceTimeArr = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);
    },

    getTeacherNameList() {
      var request = {
        params: {
          campus_id: store.state.user.campus_id
        }
      }
      axios
        .get("/teacher/getallTeacher", request)
        .then((response) => {
          if(response.data[0].length > 0) {
            this.teacherArr = response.data[0];
            this.selectedTeacher = this.teacher = this.teacherArr[0].id;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    confirmRegister() {
      this.submitted = true;
      if (this.$v.$invalid || this.class == '' || this.class == 0 || this.teacher == '' || this.teacher.length == 0) {
        return;
      }
      this.register();
    },

    register() {
      this.disabled = true;
      var selectedHour = this.attendanceData.date.split(' ')[1];
      var interval;
      selectedHour <= '12:00:00' ? interval = 1: interval = 2;
      var request = {
        teacher_ids: this.teacher,
        date: this.attendanceData.date.split(' ')[0],
        time: this.attendanceData.date.split(' ')[1],
        times: interval,
        status: this.attendanceData.status,
        remark: this.attendanceData.remark,
        campus_id: store.state.user.campus_id,
        created_id: store.state.user.id
      }

      axios.post("/teacherAttendance/create", request, {
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(response => {
          if (response.data.success == true) {
            this.$notification.success("Attendance for Teacher Added Successfully", {
              timer: 3,
              position: "bottomCenter",
            });
            this.showList();
          } else {
            this.disabled = false;
            this.$notification.error(response.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
           this.$store.commit('logout');
           window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Attendance for Teacher Create API Error", error.errors);
        });
    },

    showList() {
      this.$router.push({
        path: "/attendanceTeacher/list"
      });
    },
  },
  mounted() {
    this.isLoading = true;
    this.getAttendanceTime();
    this.getTeacherNameList();
  },
};
