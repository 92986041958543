import MainLayout from "@/components/layout/admin/MainLayout.vue";
import store from '../../store';
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import {
    required,
    maxLength
} from "vuelidate/lib/validators";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: "TeacherTypeEdit",
    components: {
        MainLayout,
        Loading
    },

    data() {
        return {
            isLoading: false,
            disabled: false,
            teacherTypeData: {},
            noDataMsg: CONFIG.NO_DATA_EXIST,
            type_name: '',
            enable_qr: CONFIG.ENABLE_QR.DISABLE,
            submitted: false,
            typeExists: false,
        };
    },

    validations: {
        teacherTypeData: {
            type_name: {
                required,
                maxLength: maxLength(20)
            },
        },
    },

    methods: {
        async checkDuplicateType() {
            try {
                const response = await axios.get("/teacherType/checkDuplicateType", {
                    params: {
                        id: this.$route.params.id,
                        type_name: this.teacherTypeData.type_name,
                        enable_qr: this.teacherTypeData.enable_qr,
                    },
                });
                this.typeExists = response.data.exists;
            } catch (error) {
                console.error('An error occurred:', error);
            }
        },

        getTeacherTypeEdit() {
            axios.get("/teacherType/detail/" + this.$route.params.id)
                .then(response => {
                    this.teacherTypeData = response.data;
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Teacher Type Detail API Error", error);
                });
        },

        async confirmUpdate() {
            this.submitted = true;
            await this.checkDuplicateType();
            if (this.$v.$invalid || this.teacherTypeData.type_name == '' || this.teacherTypeData.type_name == 0 || this.typeExists) {
                return;
            }
            this.update();
        },

        update() {
            this.disabled = true;
            var id = this.$route.params.id
            var request = {
                type_name: this.teacherTypeData.type_name,
                enable_qr: this.teacherTypeData.enable_qr,
                login_id: store.state.user.id,
            }
            axios.post("/teacherType/update/" + id, request, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(response => {
                    if (response.data) {
                        this.$notification.success("Teacher Type Updated Successfully", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.$router.push({
                            name: "teacherTypeList"
                        });
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.disabled = false;
                    this.isLoading = false;
                    console.log("Teacher Type Edit API Error", error.errors);
                });
        },

        showList() {
            this.$router.push({
                path: "/teacherType/list"
            });
        },
    },
    mounted() {
        this.isLoading = true;
        this.getTeacherTypeEdit();
    },
};
