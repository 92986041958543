import MainLayout from "@/components/layout/admin/MainLayout.vue";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import axios from "axios";

export default {
    name: "TeacherTypeList",
    components: {
        MainLayout,
        AdvancedLaravelVuePaginate,
        Loading
    },

    data() {
        return {
            noDataMsg: CONFIG.NO_DATA_FOUND,
            isLoading: false,
            search_status: false,
            type_name: "",
            teacherTypeMasterData: {},
        };
    },

    methods: {
        reset() {
            this.type_name = '';
        },

        searchTeacherType(page = 1) {
            this.isLoading = true;
            axios.get('teacherType/search?page=' + page, {
                params: {
                    type_name: this.type_name,
                }
            })
                .then(res => {
                    if (res.data) {
                        this.teacherTypeMasterData = res.data;
                    }
                    this.isLoading = false;
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getAllTeacherType(page = 1) {
            axios.get('teacherType/list?page=' + page)
            .then((response) => {
                this.teacherTypeMasterData = response.data;
                this.isLoading = false;
            })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        showCreate() {
            this.$router.push({
                path: "/teacherType/create"
            });
        },

        showEdit(id) {
            this.$router.push({
                name: "teacherTypeEdit",
                params: {
                    id: id
                }
            });
        },

        showDetail(id) {
            this.$router.push({
                name: "teacherTypeDetail",
                params: {
                    id: id
                }
            });
        },

        confirmDelete(id) {
            if (window.confirm("Are you sure you want to delete?")) {
                this.deleteTeacherType(id);
            } else {
                console.log("no");
            }
        },

        deleteTeacherType(id) {
            this.isLoading = true;
            axios.delete("/teacherType/delete/" + id)
                .then(response => {
                    if (response.data.success == true) {
                        this.getAllTeacherType();
                    } else {
                        this.$notification.error("Teacher type data is already deleted.", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.getAllTeacherType();
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Teacher Type Delete Error->", error.response.data);
                });
        },

        excelDownload() {
            this.isLoading = true;
            axios.get('/teacherType/excelDownload', {
                params: {
                    type_name: this.type_name,
                },
                responseType: 'arraybuffer'
            })
                .then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    let date = new Date();
                    fileLink.setAttribute('download', `teacher_type_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate()}.xlsx`);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.isLoading = false;
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        }
    },

    mounted() {
        this.isLoading = true;
        this.getAllTeacherType();
    },
};
