import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Select2Multiple from 'v-select2-multiple-component';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import axios from 'axios';
import store from '@/store';

export default {
    name: "leaveList",
    components: {
        MainLayout,
        Select2Multiple,
        flatPickr,
        Loading,
        CONFIG,
        AdvancedLaravelVuePaginate
    },

    data() {
        return {
            periodOptions: [
                { id: '0', text: 'Morning' },
                { id: '1', text: 'Evening' },
                { id: '2', text: 'Full' },
            ],
            leaveData: {},
            searchData: {
                period: null,
                from_date: '',
                to_date: '',
                request_date: '',
                class_id: null,
                teacher_name: '',
                student_name: '',
                status: null

            },
            isLoading: false,
            search_status: false,
            noDataMsg: CONFIG.NO_DATA_FOUND,
            adminNo: store.state.user.admin_no,
            campusId: store.state.user.campus_id,
            classArr: null,
            academicYear: [],
        };
    },

    methods: {
        getClass($yearId) {
            axios.get("class/classList/" + $yearId, {
                params: {
                    campus_id: this.$store.state.user.campus_id,
                }
            })
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Class'
                    }];
                    this.classArr = $defaultSelectOption.concat(res.data);
                    this.isLoading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },
        getThisAcademicYear() {
            this.isLoading = true;
            axios.get('period/getThisPeriod')
                .then(res => {
                    this.academicYear = res.data;
                    this.getClass(this.academicYear.id)
                    this.isLoading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },
        reset() {
            this.searchData.period = null
            this.searchData.from_date = ''
            this.searchData.to_date = ''
            this.searchData.request_date = ''
            this.searchData.class_id = ''
            this.searchData.teacher_name = ''
            this.searchData.student_name = ''
            this.searchData.status = null
        },
        searchLeave(page = 1) {
            this.isLoading = true;
            var request = {
                params: {
                    period: this.searchData.period,
                    from_date: this.searchData.from_date,
                    to_date: this.searchData.to_date,
                    request_date: this.searchData.request_date,
                    class_id: this.searchData.class_id,
                    teacher_name: this.searchData.teacher_name,
                    student_name: this.searchData.student_name,
                    status: this.searchData.status,
                    campus_id: this.$store.state.user.campus_id
                }
            }

            axios.get("/leave/search?page=" + page, request)
                .then(response => {
                    this.search_status = true;
                    this.leaveData = response.data;
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Class Search API Error", error);
                });
        },
        getLeave(page = 1) {
            axios.get('leave/list?page=' + page, {
                params: {
                    campus_id: this.campusId,
                }
            })
                .then(res => {
                    if (res.data) {
                        this.leaveData = res.data;
                        this.isLoading = false
                    }
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Class List API Error", error);
                });
        },

        confirmDelete(id) {
            if (window.confirm("Are you sure you want to delete?")) {
                this.delete(id);
            } else {
                console.log("no");
            }
        },

        delete(id) {
            axios.delete("/leave/delete/" + id)
                .then(res => {
                    if (res.data.success == true) {
                        this.isLoading = true;
                        this.getLeave();
                        this.$notification.success("Leave Deleted Successfully.", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                    }

                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                })
        },
        showCreate() {
            this.$router.push({
                path: "/leave/create"
            });
        },

        showEdit(id) {
            this.$router.push({
                name: "leaveEdit",
                params: {
                    id: id
                }
            });
        },

        showDetail(classId) {
            this.$router.push({
                name: "leaveDetail",
                params: {
                    id: classId
                }
            });
        },

        excelDownload() {
            this.isLoading = true;
            axios.get('/leave/excelDownload', {
                params: {
                    period: this.searchData.period,
                    from_date: this.searchData.from_date,
                    to_date: this.searchData.to_date,
                    request_date: this.searchData.request_date,
                    class_id: this.searchData.class_id,
                    teacher_name: this.searchData.teacher_name,
                    student_name: this.searchData.student_name,
                    status: this.searchData.status,
                    campus_id: this.$store.state.user.campus_id,
                },
                responseType: 'arraybuffer'
            })
                .then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    let date = new Date();
                    fileLink.setAttribute('download', `leave_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate()}.xlsx`);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.isLoading = false;
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        }
    },

    mounted() {
        this.isLoading = true;
        this.getThisAcademicYear();
        this.getLeave();
    },
};
