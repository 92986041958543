import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store/index.js';

import Login from "@/components/LoginPage";
import Dashboard from "@/components/HomeIndex";
import SchoolInfo from "@/components/SchoolInfo";
import UpdatePassword from "@/components/UpdatePassword";

import CampusList from "@/components/campus/CampusList";
import CampusCreate from "@/components/campus/CampusCreate";
import CampusDetail from "@/components/campus/CampusDetail";
import CampusEdit from "@/components/campus/CampusEdit";

import AdminList from "@/components/admin/AdminList";
import AdminCreate from "@/components/admin/AdminCreate";
import AdminDetail from "@/components/admin/AdminDetail";
import AdminEdit from "@/components/admin/AdminEdit";

import GradeFeeList from "@/components/grade_fee/GradeFeeList";
import GradeFeeCreate from "@/components/grade_fee/GradeFeeCreate";
import GradeFeeEdit from "@/components/grade_fee/GradeFeeEdit";
import GradeFeeDetail from "@/components/grade_fee/GradeFeeDetail";

import HolidayList from "@/components/holiday/HolidayList";
import HolidayCreate from "@/components/holiday/HolidayCreate";
import HolidayEdit from "@/components/holiday/HolidayEdit";
import HolidayDetail from "@/components/holiday/HolidayDetail";

import PaymentMethodList from "@/components/payment_method/PaymentMethodList";
import PaymentMethodCreate from "@/components/payment_method/PaymentMethodCreate";
import PaymentMethodEdit from "@/components/payment_method/PaymentMethodEdit";
import PaymentMethodDetail from "@/components/payment_method/PaymentMethodDetail";

import TeacherTypeList from "@/components/teacher_type/TeacherTypeList";
import TeacherTypeCreate from "@/components/teacher_type/TeacherTypeCreate";
import TeacherTypeEdit from "@/components/teacher_type/TeacherTypeEdit";
import TeacherTypeDetail from "@/components/teacher_type/TeacherTypeDetail";

import SubjectList from "@/components/subject/SubjectList";
import SubjectCreate from "@/components/subject/SubjectCreate";
import SubjectEdit from "@/components/subject/SubjectEdit";
import SubjectDetail from "@/components/subject/SubjectDetail";

import ClassList from "@/components/class/ClassList";
import ClassCreate from "@/components/class/ClassCreate";
import ClassEdit from "@/components/class/ClassEdit";
import ClassDetail from "@/components/class/ClassDetail";

import PeriodList from "@/components/academic_period/PeriodList";
import PeriodCreate from "@/components/academic_period/PeriodCreate";
import PeriodEdit from "@/components/academic_period/PeriodEdit";

import GradeList from "@/components/grade/GradeList";
import GradeCreate from "@/components/grade/GradeCreate";
import GradeEdit from "@/components/grade/GradeEdit";

import GradingList from "@/components/grading/GradingList";
import GradingCreate from "@/components/grading/GradingCreate";
import GradingDetail from "@/components/grading/GradingDetail";
import GradingEdit from "@/components/grading/GradingEdit";

import ExamList from "@/components/exam/ExamList";
import ExamCreate from "@/components/exam/ExamCreate";
import ExamDetail from "@/components/exam/ExamDetail";
import ExamEdit from "@/components/exam/ExamEdit";

import CampusGradeList from "@/components/campus_grade/CampusGradeList";
import CampusGradeCreate from "@/components/campus_grade/CampusGradeCreate";
import CampusGradeEdit from "@/components/campus_grade/CampusGradeEdit";

import TimetableMetaList from "@/components/timetable_meta/TimetableMetaList";
import TimetableMetaCreate from "@/components/timetable_meta/TimetableMetaCreate";
import TimetableMetaDetail from "@/components/timetable_meta/TimetableMetaDetail";
import TimetableMetaEdit from "@/components/timetable_meta/TimetableMetaEdit";

import TimetableList from "@/components/timetable/TimetableList";
import TimetableCreate from "@/components/timetable/TimetableCreate";
import TimetableDetail from "@/components/timetable/TimetableDetail";
import TimetableEdit from "@/components/timetable/TimetableEdit";

import TeacherList from "@/components/teacher/TeacherList";
import TeacherCreate from "@/components/teacher/TeacherCreate";
import TeacherProfile from "@/components/teacher/TeacherProfile";
import TeacherEdit from "@/components/teacher/TeacherEdit";

import AttendanceTeacherList from "@/components/attendance_teacher/AttendanceTeacherList";
import AttendanceTeacherCreate from "@/components/attendance_teacher/AttendanceTeacherCreate";
import AttendanceTeacherDetail from "@/components/attendance_teacher/AttendanceTeacherDetail";
import AttendanceTeacherEdit from "@/components/attendance_teacher/AttendanceTeacherEdit";

import AttendanceStudentList from "@/components/attendance_student/AttendanceStudentList";
import AttendanceStudentCreate from "@/components/attendance_student/AttendanceStudentCreate";
import AttendanceStudentDetail from "@/components/attendance_student/AttendanceStudentDetail";
import AttendanceStudentEdit from "@/components/attendance_student/AttendanceStudentEdit";

import SalaryList from "@/components/salary/SalaryList";
import SalaryCreate from "@/components/salary/SalaryCreate";
import SalaryEdit from "@/components/salary/SalaryEdit";
import SalaryDetail from "@/components/salary/SalaryDetail";

import LeaveList from "@/components/leave/LeaveList";
import LeaveCreate from "@/components/leave/LeaveCreate";
import LeaveEdit from  "@/components/leave/LeaveEdit";
import LeaveDetail from  "@/components/leave/LeaveDetail";

import CourseList from '@/components/course/CourseList'
import CourseCreate from '@/components/course/CourseCreate'
import CourseEdit from '@/components/course/CourseEdit'
import CourseDetail from '@/components/course/CourseDetail'

import NoticeList from '@/components/notice/NoticeList'
import NoticeCreate from '@/components/notice/NoticeCreate'
import NoticeDetail from '@/components/notice/NoticeDetail'
import NoticeEdit from '@/components/notice/NoticeEdit'

import SubjectTeacherList from '@/components/subject_teacher/SubjectTeacherList'
import SubjectTeacherCreate from '@/components/subject_teacher/SubjectTeacherCreate'
import SubjectTeacherEdit from '@/components/subject_teacher/SubjectTeacherEdit'
import SubjectTeacherDetail from '@/components/subject_teacher/SubjectTeacherDetail'

import TransactionList from '@/components/transaction/TransactionList'
import TransactionCreate from '@/components/transaction/TransactionCreate'
import TransactionEdit from '@/components/transaction/TransactionEdit'
import TransactionDetail from '@/components/transaction/TransactionDetail'

import StudentList from "@/components/student/StudentList";
import StudentCreate from "@/components/student/StudentCreate";
import StudentProfile from "@/components/student/StudentProfile";
import StudentEdit from "@/components/student/StudentEdit";
import StudentPass from "@/components/student/StudentPass";
import StudentFail from "@/components/student/StudentFail";

import ParentList from '@/components/parent/ParentList'
import ParentCreate from '@/components/parent/ParentCreate'
import ParentEdit from '@/components/parent/ParentEdit'
import ParentDetail from '@/components/parent/ParentDetail'

import ItemList from "@/components/item/ItemList";
import ItemCreate from "@/components/item/ItemCreate";
import ItemDetail from "@/components/item/ItemDetail";
import ItemEdit from "@/components/item/ItemEdit";

import PaymentRequestList from "@/components/payment/PaymentRequestList";
import PaymentRequestDetail from "@/components/payment/PaymentRequestDetail";
import PaymentList from "@/components/payment/PaymentList";
import PaymentCreate from "@/components/payment/PaymentCreate";
import PaymentDetail from "@/components/payment/PaymentDetail";
import PaymentEdit from "@/components/payment/PaymentEdit";
import PaymentCheckout from "@/components/payment/PaymentCheckout";

import FerryCreate from "@/components/ferry/FerryCreate";
import FerryList from "@/components/ferry/FerryList";
import FerryEdit from "@/components/ferry/FerryEdit";
import FerryDetail from "@/components/ferry/FerryDetail";

import DriverList from "@/components/driver/DriverList";
import DriverCreate from "@/components/driver/DriverCreate";
import DriverEdit from "@/components/driver/DriverEdit";
import DriverDetail from "@/components/driver/DriverDetail";

import RouteList from "@/components/route/RouteList";
import RouteCreate from "@/components/route/RouteCreate";
import RouteEdit from "@/components/route/RouteEdit";
import RouteDetail from "@/components/route/RouteDetail";

import FerryPickupList from "@/components/ferry_pickup/FerryPickupList";
import FerryPickupDetail from "@/components/ferry_pickup/FerryPickupDetail";
import FerryPickupEdit from "@/components/ferry_pickup/FerryPickupEdit";

import DailyFerryList from "@/components/daily_ferry/DailyFerryList";
import DailyFerryCreate from "@/components/daily_ferry/DailyFerryCreate";
import DailyFerryEdit from "@/components/daily_ferry/DailyFerryEdit";
import DailyFerryDetail from "@/components/daily_ferry/DailyFerryDetail";

import AboutUs from "@/components/AboutUs";
import PrivacyPolicy from "@/components/PrivacyPolicy";

import SettingPage from "@/components/setting/SettingPage";
import SettingEdit from "@/components/setting/SettingEdit";

import ChatRequestList from "@/components/chat/RequestList";

import CategoryItems from "@/components/library/categoryItems.vue";
import AppFormList from "@/components/application_form/appFormList";
import AppFormCreate from "@/components/application_form/appFormCreate";
import AppFormItems from "@/components/application_form/appFormItems";
import AppFormDetail from "@/components/application_form/appFormDetail";
import AppFormEdit from "@/components/application_form/appFormEdit";
import AppFormEditItems from "@/components/application_form/appFormEditItems";
import InitialScreenPreview from "@/components/application_form/initialScreenPreview";

import ApplicantList from "@/components/applicant/ApplicantList";
import ApplicantDetail from "@/components/applicant/ApplicantDetail";

import BookList from "@/components/book_library/BookList";
import BookCreate from "@/components/book_library/BookCreate";
import BookDetail from "@/components/book_library/BookDetail";
import BookEdit from "@/components/book_library/BookEdit";

import LibraryMemberList from "@/components/library_member/LibraryMemberList";
import LibraryMemberCreate from "@/components/library_member/LibraryMemberCreate";
import LibraryMemberDetail from "@/components/library_member/LibraryMemberDetail";
import LibraryMemberEdit from "@/components/library_member/LibraryMemberEdit";

import CheckoutCreate from "@/components/checkout/CheckoutCreate";
import CheckoutList from "@/components/checkout/CheckoutList";
import CheckoutDetail from "@/components/checkout/CheckoutDetail";
import CheckoutEdit from "@/components/checkout/CheckoutEdit";

import DamageLossesCreate from "@/components/damage-losses/DamageLossesCreate";
import DamageLossesList from "@/components/damage-losses/DamageLossesList";
import DamageLossesDetail from "@/components/damage-losses/DamageLossesDetail";
import DamageLossesEdit from "@/components/damage-losses/DamageLossesEdit";

import AttendanceRateList from "@/components/attendance_rate/AttendanceRateList";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },

  {
    path: "/campus/list",
    name: "campusList",
    component: CampusList
  },
  {
    path: "/campus/create",
    name: "campusCreate",
    component: CampusCreate
  },
  {
    path: "/campus/detail/:id",
    name: "campusDetail",
    component: CampusDetail
  },
  {
    path: "/campus/edit/:id",
    name: "campusEdit",
    component: CampusEdit
  },

  {
    path: "/admin/list",
    name: "adminList",
    component: AdminList,
  },
  {
    path: "/admin/create",
    name: "adminCreate",
    component: AdminCreate,
  },
  {
    path: "/admin/detail/:id",
    name: "adminDetail",
    component: AdminDetail,
  },
  {
    path: "/admin/edit/:id",
    name: "adminEdit",
    component: AdminEdit,
  },
  {
    path: "/gradeFee/list",
    name: "gradeFeeList",
    component: GradeFeeList,
  },
  {
    path: "/gradeFee/create",
    name: "gradeFeeCreate",
    component: GradeFeeCreate,
  },
  {
    path: "/gradeFee/edit/:id",
    name: "gradeFeeEdit",
    component: GradeFeeEdit,
  },
  {
    path: "/gradeFee/detail/:id",
    name: "gradeFeeDetail",
    component: GradeFeeDetail,
  },
  {
    path: "/holiday/list",
    name: "holidayList",
    component: HolidayList,
  },
  {
    path: "/holiday/create",
    name: "holidayCreate",
    component: HolidayCreate,
  },
  {
    path: "/holiday/edit/:id",
    name: "holidayEdit",
    component: HolidayEdit,
  },
  {
    path: "/holiday/detail/:id",
    name: "holidayDetail",
    component: HolidayDetail,
  },
  {
    path: "/paymentMethod/list",
    name: "paymentMethodList",
    component: PaymentMethodList,
  },
  {
    path: "/paymentMethod/create",
    name: "paymentMethodCreate",
    component: PaymentMethodCreate,
  },
  {
    path: "/paymentMethod/edit/:id",
    name: "paymentMethodEdit",
    component: PaymentMethodEdit,
  },
  {
    path: "/paymentMethod/detail/:id",
    name: "paymentMethodDetail",
    component: PaymentMethodDetail,
  },
  {
    path: "/teacherType/list",
    name: "teacherTypeList",
    component: TeacherTypeList,
  },
  {
    path: "/teacherType/create",
    name: "teacherTypeCreate",
    component: TeacherTypeCreate,
  },
  {
    path: "/teacherType/edit/:id",
    name: "teacherTypeEdit",
    component: TeacherTypeEdit,
  },
  {
    path: "/teacherType/detail/:id",
    name: "teacherTypeDetail",
    component: TeacherTypeDetail,
  },
  {
    path: "/subject/list",
    name: "subjectList",
    component: SubjectList,
  },
  {
    path: "/subject/create",
    name: "subjectCreate",
    component: SubjectCreate,
  },
  {
    path: "/subject/edit/:campusGradeId",
    name: "subjectEdit",
    component: SubjectEdit,
  },
  {
    path: "/subject/detail/:campusGradeId",
    name: "subjectDetail",
    component: SubjectDetail,
  },
  {
    path: "/class/list",
    name: "classList",
    component: ClassList,
  },
  {
    path: "/class/create",
    name: "classCreate",
    component: ClassCreate,
  },
  {
    path: "/class/edit/:id",
    name: "classEdit",
    component: ClassEdit,
  },
  {
    path: "/class/detail/:id",
    name: "classDetail",
    component: ClassDetail,
  },
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
  },
  {
    path: "/school/:id",
    name: "school",
    component: SchoolInfo,
  },
  {
    path: "/updatePassword",
    name: "updatePassword",
    component: UpdatePassword,
  },
  {
    path: "/period/list",
    name: "periodList",
    component: PeriodList,
  },
  {
    path: "/period/create",
    name: "periodCreate",
    component: PeriodCreate,
  },
  {
    path: "/period/edit/:id",
    name: "periodEdit",
    component: PeriodEdit,
  },
  {
    path: "/grade/list",
    name: "gradeList",
    component: GradeList,
  },
  {
    path: "/grade/create",
    name: "gradeCreate",
    component: GradeCreate,
  },
  {
    path: "/grade/edit/:id",
    name: "gradeEdit",
    component: GradeEdit,
  },
  {
    path: "/grading/list",
    name: "gradingList",
    component: GradingList,
  },
  {
    path: "/grading/create",
    name: "gradingCreate",
    component: GradingCreate,
  },
  {
    path: "/grading/detail/:student_id/:exam_id",
    name: "gradingDetail",
    component: GradingDetail,
  },
  {
    path: "/grading/edit/:student_id/:exam_id",
    name: "gradingEdit",
    component: GradingEdit,
  },
  {
    path: "/exam/list",
    name: "examList",
    component: ExamList,
  },
  {
    path: "/exam/create",
    name: "examCreate",
    component: ExamCreate,
  },
  {
    path: "/exam/detail/:exam_id",
    name: "examDetail",
    component: ExamDetail,
  },
  {
    path: "/exam/edit/:exam_id",
    name: "examEdit",
    component: ExamEdit,
  },
  {
    path: "/campusGrade/list",
    name: "campusGradeList",
    component: CampusGradeList,
  },
  {
    path: "/campusGrade/create",
    name: "campusGradeCreate",
    component: CampusGradeCreate,
  },
  {
    path: "/campusGrade/edit/:id",
    name: "campusGradeEdit",
    component: CampusGradeEdit,
  },
  {
    path: "/timetableMeta/list",
    name: "timetableMetaList",
    component: TimetableMetaList,
  },
  {
    path: "/timetableMeta/create",
    name: "timetableMetaCreate",
    component: TimetableMetaCreate,
  },
  {
    path: "/timetableMeta/detail/:id",
    name: "timetableMetaDetail",
    component: TimetableMetaDetail,
  },
  {
    path: "/timetableMeta/edit/:id",
    name: "timetableMetaEdit",
    component: TimetableMetaEdit,
  },
  {
    path: "/timetable/list",
    name: "timetableList",
    component: TimetableList,
  },
  {
    path: "/timetable/create",
    name: "timetableCreate",
    component: TimetableCreate,
  },
  {
    path: "/timetable/detail/:id",
    name: "timetableDetail",
    component: TimetableDetail,
  },
  {
    path: "/timetable/edit/:id",
    name: "timetableEdit",
    component: TimetableEdit,
  },
  {
    path: "/teacher/list",
    name: "teacherList",
    component: TeacherList,
  },
  {
    path: "/teacher/create",
    name: "teacherCreate",
    component: TeacherCreate,
  },
  {
    path: "/teacher/profile/:id",
    name: "teacherProfile",
    component: TeacherProfile,
  },
  {
    path: "/teacher/edit/:id",
    name: "teacherEdit",
    component: TeacherEdit,
  },
  {
    path: "/salary/list",
    name: "salaryList",
    component: SalaryList,
  },
  {
    path: "/salary/create",
    name: "salaryCreate",
    component: SalaryCreate,
  },
  {
    path: "/salary/edit/:id",
    name: "salaryEdit",
    component: SalaryEdit,
  },
  {
    path: "/salary/detail/:id",
    name: "salaryDetail",
    component: SalaryDetail,
  },
  {
    path: "/attendanceTeacher/list",
    name: "attendanceTeacherList",
    component: AttendanceTeacherList,
  },
  {
    path: "/attendanceTeacher/create",
    name: "attendanceTeacherCreate",
    component: AttendanceTeacherCreate,
  },
  {
    path: "/attendanceTeacher/detail/:teacher_id/:date",
    name: "attendanceTeacherDetail",
    component: AttendanceTeacherDetail,
  },
  {
    path: "/attendanceTeacher/edit/:teacher_id/:date",
    name: "attendanceTeacherEdit",
    component: AttendanceTeacherEdit,
  },
  {
    path: "/attendanceStudent/list",
    name: "attendanceStudentList",
    component: AttendanceStudentList,
  },
  {
    path: "/attendanceStudent/create",
    name: "attendanceStudentCreate",
    component: AttendanceStudentCreate,
  },
  {
    path: "/attendanceStudent/detail/:id",
    name: "attendanceStudentDetail",
    component: AttendanceStudentDetail,
  },
  {
    path: "/attendanceStudent/edit/:id",
    name: "attendanceStudentEdit",
    component: AttendanceStudentEdit,
  },
  {
    path: "/leave/list",
    name: "leaveList",
    component:LeaveList
  },
  {
    path: "/leave/create",
    name: "leaveCreate",
    component: LeaveCreate
  },
  {
    path: "/leave/edit/:id",
    name: "leaveEdit",
    component:LeaveEdit
  },
  {
    path: "/leave/detail/:id",
    name: "leaveDetail",
    component:LeaveDetail
  },

  {
    path: "/course/list",
    name: "courseList",
    component:CourseList
  },
  {
    path: "/course/create",
    name: "courseCreate",
    component:CourseCreate
  },
  {
    path: "/course/edit/:id",
    name: "courseEdit",
    component:CourseEdit
  },
  {
    path: "/course/detail/:id",
    name: "courseDetail",
    component:CourseDetail
  },

  {
    path: "/notice/list",
    name: "noticeList",
    component:NoticeList
  },
  {
    path: "/notice/create",
    name: "noticeCreate",
    component:NoticeCreate
  },
  {
    path: "/notice/detail/:id",
    name: "noticeDetail",
    component:NoticeDetail
  },
  {
    path: "/notice/edit/:id",
    name: "noticeEdit",
    component:NoticeEdit
  },
  {
    path: "/subjectTeacher/list",
    name: "subjectTeacherList",
    component: SubjectTeacherList
  },
  {
    path: "/subjectTeacher/create",
    name: "subjectTeacherCreate",
    component: SubjectTeacherCreate
  },
  {
    path: "/subjectTeacher/edit/:id",
    name: "subjectTeacherEdit",
    component: SubjectTeacherEdit
  },
  {
    path: "/subjectTeacher/detail/:id",
    name: "subjectTeacherDetail",
    component: SubjectTeacherDetail
  },
  {
    path: "/transaction/list",
    name: "transactionList",
    component: TransactionList
  },
  {
    path: "/transaction/create",
    name: "transactionCreate",
    component: TransactionCreate
  },
  {
    path: "/transaction/detail/:id",
    name: "transactionDetail",
    component: TransactionDetail
  },
  {
    path: "/transaction/edit/:id",
    name: "transactionEdit",
    component: TransactionEdit
  },
  {
    path: "/student/list",
    name: "studentList",
    component: StudentList,
  },
  {
    path: "/student/create/:parentId",
    name: "studentCreate",
    component: StudentCreate,
  },
  {
    path: "/student/profile/:leavedStatus/:id",
    name: "studentProfile",
    component: StudentProfile,
  },
  {
    path: "/student/edit/:id",
    name: "studentEdit",
    component: StudentEdit,
  },
  {
    path: "/student/pass/:id",
    name: "studentPass",
    component: StudentPass,
  },
  {
    path: "/student/fail/:id",
    name: "studentFail",
    component: StudentFail,
  },
  {
    path: "/parent/list",
    name: "parentList",
    component: ParentList
  },
  {
    path: "/parent/create",
    name: "parentCreate",
    component: ParentCreate
  },
  {
    path: "/parent/edit/:id",
    name: "parentEdit",
    component: ParentEdit
  },
  {
    path: "/parent/detail/:id",
    name: "parentDetail",
    component: ParentDetail
  },
  {
    path: "/item/list",
    name: "itemList",
    component: ItemList,
    meta: { restricted: "staff" }
  },
  {
    path: "/item/create",
    name: "itemCreate",
    component: ItemCreate,
    meta: { restricted: "staff" }
  },

  {
    path: "/item/detail/:id",
    name: "itemDetail",
    component: ItemDetail,
    meta: { restricted: "staff" }
  },

  {
    path: "/item/edit/:id",
    name: "itemEdit",
    component: ItemEdit,
    meta: { restricted: "staff" }
  },

  {
    path: "/payment_request/list",
    name: "paymentRequestList",
    component: PaymentRequestList,
    meta: { restricted: "staff" }
  },

  {
    path: "/payment/list",
    name: "paymentList",
    component: PaymentList,
    meta: { restricted: "staff" }
  },

  {
    path: "/payment/create",
    name: "paymentCreate",
    component: PaymentCreate,
    meta: { restricted: "staff" }
  },

  {
    path: "/payment/detail/:id/:classId",
    name: "paymentDetail",
    component: PaymentDetail,
    meta: { restricted: "staff" }
  },

  {
    path: "/payment_request/detail/:id/:classId",
    name: "paymentRequestDetail",
    component: PaymentRequestDetail,
    meta: { restricted: "staff" }
  },

  {
    path: "/payment/edit/:id/:classId",
    name: "paymentEdit",
    component: PaymentEdit,
    meta: { restricted: "staff" }
  },

  {
    path: "/payment/checkout",
    name: "paymentCheckout",
    component: PaymentCheckout,
  },

  {
    path: "/driver/list",
    name: "driverList",
    component: DriverList,
  },

  {
    path: "/driver/create",
    name: "driverCreate",
    component: DriverCreate,
  },

  {
    path: "/driver/edit/:id",
    name: "driverEdit",
    component: DriverEdit,
  },

  {
    path: "/driver/detail/:id",
    name: "driverDetail",
    component: DriverDetail,
  },
  
  {
    path: "/ferry/create",
    name: "ferryCreate",
    component: FerryCreate,
  }, 

  {
    path: "/ferry/list",
    name: "ferryList",
    component: FerryList,
  },

  {
    path: "/ferry/edit/:id",
    name: "ferryEdit",
    component: FerryEdit,
  },

  {
    path: "/ferry/detail/:id",
    name: "ferryDetail",
    component: FerryDetail,
  },
  
  {
    path: "/route/list",
    name: "routeList",
    component: RouteList,
  },

  {
    path: "/route/create",
    name: "routeCreate",
    component: RouteCreate,
  },

  {
    path: "/route/edit/:id",
    name: "routeEdit",
    component: RouteEdit,
  },

  {
    path: "/route/detail/:id",
    name: "routeDetail",
    component: RouteDetail,
  },

  {
    path: "/ferryPickup/list",
    name: "ferryPickupList",
    component: FerryPickupList,
  },

  {
    path: "/ferryPickup/detail/:status/:id",
    name: "ferryPickupDetail",
    component: FerryPickupDetail,
    props: true,
  },

  {
    path: "/ferryPickup/edit/:status/:id",
    name: "ferryPickupEdit",
    component: FerryPickupEdit
  },

  {
    path: "/dailyFerry/list",
    name: "dailyFerryList",
    component: DailyFerryList,
  },

  {
    path: "/dailyFerry/create",
    name: "dailyFerryCreate",
    component: DailyFerryCreate,
  },

  {
    path: "/dailyFerry/edit/:id",
    name: "dailyFerryEdit",
    component: DailyFerryEdit,
  },

  {
    path: "/dailyFerry/detail/:id",
    name: "dailyFerryDetail",
    component: DailyFerryDetail,
  },

  {
    path: "/aboutUs",
    name: "aboutUs",
    component: AboutUs,
  },

  {
    path: "/privacyPolicy",
    name: "privacyPolicy",
    component: PrivacyPolicy,
    meta: { public: true },
  },

  {
    path: "/setting",
    name: "settingPage",
    component: SettingPage,
    meta: { restricted: "staff" }
  },

  {
    path: "/setting/edit",
    name: "settingEdit",
    component: SettingEdit,
    meta: { restricted: "staff" }
  },

  {
    path: "/chat/request/list",
    name: "chatRequestList",
    component: ChatRequestList,
  },

  {
    path: "/library/category",
    name: "categoryItems",
    component: CategoryItems,
  },
  
  {
    path: "/applicationForm/list",
    name: "appFormList",
    component: AppFormList,
  },

  {
    path: "/applicationForm/create",
    name: "appFormCreate",
    component: AppFormCreate,
  },

  {
    path: "/applicationForm/items",
    name: "appFormItems",
    component: AppFormItems,
  },

  {
    path: "/applicationForm/detail/:id",
    name: "appFormDetail",
    component: AppFormDetail
  },

  {
    path: "/applicationForm/edit/:id",
    name: "appFormEdit",
    component: AppFormEdit
  },

  {
    path: "/applicationForm/edit/:id/items",
    name: "appFormEditItems",
    component: AppFormEditItems
  },

  {
    path: "/applicationForm/preview",
    name: "initialScreenPreview",
    component: InitialScreenPreview,
  },
  
  {
    path: "/applicant/list",
    name: "applicantList",
    component: ApplicantList,
  },
  
  {
    path: "/applicant/detail/:id",
    name: "applicantDetail",
    component: ApplicantDetail,
  },

  {
    path: "/book_library/list",
    name: "bookList",
    component: BookList,
  },
  {
    path: "/book_library/create",
    name: "bookCreate",
    component: BookCreate,
  },
  {
    path: "/book_library/detail/:id",
    name: "bookDetail",
    component: BookDetail,
  },
  {
    path: "/book_library/edit/:id",
    name: "bookEdit",
    component: BookEdit,
  },
  {
    path: "/libraryMember/list",
    name: "libraryMemberList",
    component: LibraryMemberList,
  },
  {
    path: "/libraryMember/create",
    name: "libraryMemberCreate",
    component: LibraryMemberCreate,
  },
  {
    path: "/libraryMember/detail/:id",
    name: "libraryMemberDetail",
    component: LibraryMemberDetail,
  },
  {
    path: "/libraryMember/edit/:id",
    name: "libraryMemberEdit",
    component: LibraryMemberEdit,
  },
  {
    path: "/checkout/list",
    name: "checkoutList",
    component: CheckoutList,
  },
  {
    path: "/checkout/create",
    name: "checkoutCreate",
    component: CheckoutCreate,
  },
  {
    path: "/checkout/detail/:id",
    name: "checkoutDetail",
    component: CheckoutDetail,
  },
  {
    path: "/checkout/edit/:id",
    name: "checkoutEdit",
    component: CheckoutEdit,
  },

  {
    path: "/damagelosses/list",
    name: "damageLossesList",
    component: DamageLossesList,
  },
  {
    path: "/damagelosses/create",
    name: "damageLossesCreate",
    component: DamageLossesCreate,
  },
  {
    path: "/damagelosses/detail/:id",
    name: "damageLossesDetail",
    component: DamageLossesDetail,
  },
  {
    path: "/damagelosses/edit/:id",
    name: "damageLossesEdit",
    component: DamageLossesEdit,
  },
  {
    path: "/attendance-rate/list",
    name: "attendanceRateList",
    component: AttendanceRateList,
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
});

router.beforeEach((to, from, next) => {
  let authUser = store.state.user.admin_no;
  if (to.name !== 'login' && !authUser)
    next({ name: 'login' })
  else next()
})

router.beforeEach((to, from, next) => {
  let authUser = store.state.user.role;
  let adminUser = store.state.user.admin_no;
  if (to.meta.restricted) {
    if (authUser === 2) {
      if (to.meta.restricted == "staff") {
        next({ name: "dashboard" });
      } else {
        next();
      }
    } else if (adminUser != "0001") {
      if (to.meta.restricted === "admin") {
        next({ name: "dashboard" });
      } else {
        next();
      }
    } else if (adminUser == "0001") {
      if (to.meta.restricted === "superAdmin") {
        next({ name: "adminList" });
      } else {
        next();
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

router.beforeEach((to,from,next)=>{
  if(to.name === "teacherTypeEdit" && to.params.id === '1'){
    next({name: "teacherTypeList"})
  } else {
    next();
  }
});

router.beforeEach(async (to, from, next)=> {
  let adminUser = store.state.user.admin_no;
  if (adminUser != "0001") {
    await store.dispatch('getSetting');
  }
  next();
});

router.beforeEach((to,from,next)=>{
  if(to.name === "chatRequestList" && !store.state.setting.teacherChatApproval){
    next({ name: "dashboard"});
  } else {
    next();
  }
});

export default router;
