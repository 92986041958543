import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import { required, maxLength } from "vuelidate/lib/validators";
import CONFIG from "@/assets/js/config.js";
import Vue from 'vue';

export default {
  name: "attendanceTeacherList",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      isLoading: false,
      submitted: false,
      disabled: false,
      attendanceData: {},
      attendanceTimeArr: null,
      editData: [],
      inputData: {
        id: '',
        campus_id: '',
        teacher_id: '',
        date: '',
        status: '',
        time: '',
        times: '',
        remark: '',
      },
      inputInfo: [],
      noDataMsg: CONFIG.NO_DATA_EXIST,
      configDateTime: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i:S",
        time_24hr: true,
        minuteIncrement: 1,
        secondIncrement: 1,
        defaultHour: 8,
      },
      teacher_id: '',
      date: null,
      timesValidation: false
    };
  },

  validations: {
    inputData: {
      time: {
        required
      },
      status: {
        required
      },
      remark: {
        maxLength: maxLength(1000)
      },
    }
  },

  methods: {
    getAttendanceTime() {
      axios.get('getAttendanceTime')
        .then(res => {
          this.attendanceTimeArr = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getAttendanceData () {
      var request = {
        params: {
          teacher_id: this.$route.params.teacher_id,
          date: this.$route.params.date
        }
      }
      axios.get("/teacherAttendance/detail", request)
        .then(response => {
          this.inputInfo = response.data;
          this.date = this.inputInfo[0].date;
          this.teacher_id = this.inputInfo[0].teacher_id;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    addEditData() {
      this.inputInfo.push(Vue.util.extend({}, this.editData));
      this.editData = [];
    },

    reset() {
      this.submitted = false;
      if (this.editData.id != null) {
        this.addEditData();
      }
      this.inputData = [];
      this.inputData.date = this.inputInfo[0].date;
    },

    addData() {
      if (this.inputData.time) {
        this.inputInfo.push(Vue.util.extend({}, this.inputData));
      }
    },

    getEditData(data) {
      this.editData.campus_id = data.campus_id;
      this.editData.teacher_id = data.teacher_id;
      this.editData.date = data.date;
      this.editData.status = data.status;
      this.editData.time = data.time;
      this.editData.times = data.times;
      this.editData.remark = data.remark;
      this.editData.id = data.id ? data.id : '';
    },

    editClone(index) {
      this.addData();
      this.getEditData(this.inputInfo[index]);
      this.inputData = this.inputInfo[index];
      Vue.delete(this.inputInfo, index);
    },

    removeClone(index) {
      Vue.delete(this.inputInfo, index);
    },

    cloneForm() {
      this.editData = [];
      this.submitted = true;
      this.inputData.date = this.date;
      this.inputData.times = this.inputData.time < '12:00:00' ? 1 : 2;
      if (this.$v.$invalid) {
        return;
      }
      if (this.inputInfo.length != 0) {
        if (this.inputInfo[0].times == this.inputData.times) {
          this.timesValidation = true;
          return;
        } else {
          this.timesValidation = false;
        }
      }
      if (this.inputInfo.length < 2) {
        this.inputInfo.push(Vue.util.extend({}, this.inputData));
        this.inputData = [];
    } else {
        alert('Attendance for each day can be created at most 2.');
      }
      this.submitted = false;
    },

    confirmUpdate(){
      this.submitted = true;
      if (this.$v.$invalid || this.times == '' || this.times == 0) {
        return;
      }
      this.update();
    },

    update() {
      this.disabled = true;
      axios.post("/teacherAttendance/update", {
          inputInfo: this.inputInfo,
          length: this.inputInfo.length,
          loginId: this.loginId,
          teacher_id: this.teacher_id,
          campus_id: this.$store.state.user.campus_id,
          updated_id: this.$store.state.user.id
      })
        .then(response => {
          if (response.data.success == true) {
            this.$notification.success("Attendance Updated Successfully", {
              timer: 3,
              position: "bottomCenter",
            });
            this.showList();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.disabled = false;
        });
    },

    showList() {
      this.$router.push({
        path: "/attendanceTeacher/list"
      });
    },
  },
  mounted() {
    this.isLoading = true;
    this.getAttendanceTime();
    this.getAttendanceData();
  },
};
