import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Select2Multiple from "v-select2-multiple-component";
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import AdvancedLaravelVuePaginate from "advanced-laravel-vue-paginate";
import "advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css";
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "ParentList",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    AdvancedLaravelVuePaginate,
    Loading
  },

  data() {
    return {
      noDataMsg: CONFIG.NO_DATA_FOUND,
      searchData: {
        name: '',
        parent_no: '',
        campus_id: '',
      },
      parentData: {},
      campusArr: [],
      isLoading: false,
    };
  },

  methods: {
    campusNameAction() {
        axios.get('campus/campusName')
        .then(res => {
          this.campusArr = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getParentList(page = 1) {
      axios.get("/parent/list?page=" + page)
        .then((response) => {
          this.parentData = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    reset() {
      this.searchData.parent_no = '';
      this.searchData.name = '';
      this.searchData.campus_id = '';
    },

    search() {
      this.isLoading = true;
      this.searchParent();
    },

    searchParent(page = 1) {
      axios.get("parent/search?page=" + page, {
          params: {
            parent_no: this.searchData.parent_no,
            name: this.searchData.name,
            campus_id: this.searchData.campus_id,
        }
        })
        .then((response) => {
          this.parentData = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
        });
    },

    confirmDelete(id) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.deleteParent(id);
      }
    },

    deleteParent(id) {
      axios.delete("/parent/delete/" + id)
        .then(response => {
          if (response.data.success == true) {
            this.isLoading = true;
            this.getParentList();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          console.log("Parent Delete Error->", error);
        });
    },

    showCreate() {
      this.$router.push({
        path: "/parent/create",
      });
    },

    showEdit(id) {
      this.$router.push({
        name: "parentEdit",
        params: {
          id: id,
        },
      });
    },

    showDetail(id) {
      this.$router.push({
        name: "parentDetail",
        params: {
          id: id
        }
      });
    },

    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);
    },

    excelDownload(status) {
      this.isLoading = true;
      axios.get('/parent/excelDownload', {
        params: {
          parent_no: this.searchData.parent_no,
          name: this.searchData.name,
          campus_id: this.searchData.campus_id,
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let date = new Date();
          fileLink.setAttribute('download', status + `_parent_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate()}.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$router.push({
              name: "login"
            });
          }
          this.isLoading = false;
        });
    },
  },

  mounted() {
    this.isLoading = true;
    this.getParentList();
    this.campusNameAction();
  },
};
