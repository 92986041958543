import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import store from "@/store";
import {
  required
} from "vuelidate/lib/validators";

export default {
  name: "timetableCreate",
  components: {
    MainLayout,
    Select2Multiple,
    Loading,
  },

  data() {
    return {
      submitted: false,
      isLoading: false,
      academicYear: [],
      academicYearArr: null,
      classArr: null,
      subjectCount: [],
      subjectArr: [],
      acd_year_id: '',
      class_id: null,
      days: null,
      time: null,
      dayCount: 0,
      timeCount: 0,
      rows: new Array(7).fill([]).map(() => new Array(10).fill("")),
      noClassMsg: '',
    };
  },

  validations: {
    acd_year_id: {
      required
    },
    class_id: {
      required
    }
  },

  methods: {
    getCurrentPeriodList() {
      axios.get('/getAcademicYear', {
        params: {
          campus_id: store.state.user.campus_id,
        }
      })
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Academic Year'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data.academicYearArr);
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getClass($yearId) {
      axios.get("class/classList/" + $yearId, {
        params: {
          campus_id: store.state.user.campus_id,
        }
      })
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Class'
          }];
          this.classArr = $defaultSelectOption.concat(res.data);
          if (this.classArr.length > 1) {
            this.noClassMsg = '';
          } else {
            this.noClassMsg = 'There is no class to show.';
          }
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
    
    changeEvent(val) {
      this.rows = new Array(7).fill([]).map(() => new Array(10).fill(""));
      this.isLoading = true;
      this.getTimetableMeta(val);
      this.subjectArr = [];
      this.getSubjectByClassId(val);
    },

    changeAcademicYearEvent($acd_year_id) {
      this.class_id = null;
      this.subjectArr = [];
      this.timeCount = 0;
      this.noClassMsg = '';
      this.getClass($acd_year_id);
    },

    getSubjectByClassId($classId) {
      axios.get("/class/subjectByClassId/" + $classId, {
        params: {
          acd_year_id: this.acd_year_id,
          campus_id: this.$store.state.user.campus_id
        }
      })         
      .then((response) => {
        this.subjectArr = response.data.subjectArr[0];
        this.isLoading = false;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    getTimetableMeta($classId) {
      axios.get("/timetableMeta/getDataForTimetable/" + $classId)         
      .then((response) => {
        this.days = response.data[0][0];
        this.time = response.data[0][1];
        this.dayCount = this.days.length;
        this.timeCount = this.time.length;
        this.isLoading = false;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    create() {
      this.submitted = true;
      if (this.$v.$invalid || this.class_id != null && this.timeCount == 0) {
        return;
      }

      this.isLoading = true;
      const preparedData = [];
      for (let rowIndex = 0; rowIndex < this.days.length; rowIndex++) {
        for (let columnIndex = 0; columnIndex < this.time.length; columnIndex++) {
          const dayId = this.days[rowIndex].id;
          const timeId = this.time[columnIndex].id;
          const subjectId = this.rows[rowIndex][columnIndex];
          const rowData = {
            day_id: dayId,
            time_id: timeId,
          };
          if (subjectId !== undefined) {
            rowData.subject_id = subjectId;
          }
          preparedData.push(rowData);
        }
      }

      axios.post("/timetable/create", {
          items: preparedData,
          class_id: this.class_id,
          login_id: this.$store.state.user.id
        })
        .then(response => {
          if (response.data.status == 200) {
            this.$notification.success("Timetable Added Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: "timetableList"
            });
          }
          if (response.data.status == 201) {
            this.$notification.error("Timetable Data Already Exist", {
              timer: 3,
              position: "bottomCenter"
            });
              this.isLoading = false;
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
        });
    },
  },

  mounted() {
    this.getCurrentPeriodList();
  },
};
