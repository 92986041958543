import { render, staticRenderFns } from "./PeriodEdit.vue?vue&type=template&id=a524cf00"
import script from "../../controllers/academic_period/period-edit.js?vue&type=script&lang=js&external"
export * from "../../controllers/academic_period/period-edit.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports