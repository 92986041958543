import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Select2Multiple from "v-select2-multiple-component";
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import AdvancedLaravelVuePaginate from "advanced-laravel-vue-paginate";
import "advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css";
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "DriverList",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    AdvancedLaravelVuePaginate,
    Loading
  },

  data() {
    return {
      noDataMsg: CONFIG.NO_DATA_FOUND,
      searchData: {},
      driverData: {},
      isLoading: false,
      total : null
    };
  },

  methods: {
    getDriver(page = 1) {
      axios.get('driver/list?page=' + page, { params: { campus_id: this.$store.state.user.campus_id } })
        .then(res => {
          if (res.data) {
            this.driverData = res.data;
            this.total = this.driverData.meta.total;
          }
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    reset() {
      this.searchData = {}
    },

    search(page = 1) {
      this.isLoading = true;
      axios.get('driver/search?page=' + page, {
        params: {
          name: this.searchData.name,
          driver_no: this.searchData.driver_no,
          father_name: this.searchData.father_name,
          start_date: this.searchData.start_date,
          end_date: this.searchData.end_date,
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then(res => {
          this.isLoading = false;
          if (res.data) {
            this.driverData = res.data;
            this.total = this.driverData.meta.total;
          }
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    excelDownload() {
      this.isLoading = true;
      axios.get('/driver/excelDownload', {
        params: {
          name: this.searchData.name,
          driver_no: this.searchData.driver_no,
          father_name: this.searchData.father_name,
          start_date: this.searchData.start_date,
          end_date: this.searchData.end_date,
          campus_id: this.$store.state.user.campus_id
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let date = new Date();
          fileLink.setAttribute('download', `driver_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate() }.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    confirmDelete(id) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.delete(id);
      } else {
        console.log("no");
      }
    },

    delete(id) {
      this.isLoading = true;
      axios.delete(`driver/delete/${id}`)
        .then(res => {
          if (res.data.success == true)
            this.getDriver();
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

  },

  mounted() {
    this.isLoading = true;
    this.getDriver();
  },
};
