import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css'
import CONFIG from "@/assets/js/config.js";
import axios from 'axios';

export default {
  name: "ItemList",
  components: {
    MainLayout,
    Loading,
    CONFIG,
    flatPickr,
    AdvancedLaravelVuePaginate,
    Select2Multiple,
  },

  data() {
    return {
      itemData: {},
      total: null,
      noDataMsg: CONFIG.NO_DATA_FOUND,
      isLoading: false,
      searchData: [],
    };
  },

  methods: {
    getItem(page = 1) {
      axios.get('item/list?page=' + page)
        .then(res => {
          if (res.data) {
            this.itemData = res.data;
            this.total = this.itemData.meta.total;
          }
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    search(page = 1) {
      this.isLoading = true;
      axios.get('item/search?page=' + page, {
        params: {
          itemName: this.searchData.itemName,
          fromAmt: this.searchData.fromAmt,
          toAmt: this.searchData.toAmt
        }
      })
        .then((res) => {
          this.isLoading = false;
          if (res.data) {
            this.itemData = res.data;
            this.total = this.itemData.meta.total;
          }
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    reset() {
      this.searchData = [];
    },

    excelDownload() {
      this.isLoading = true;
      axios.get('/item/excelDownload', {
        params: {
          itemName: this.searchData.itemName,
          fromAmt: this.searchData.fromAmt,
          toAmt: this.searchData.toAmt
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          let date = new Date();
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `payment_item_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate()}.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.isLoading = true;
    this.getItem();
  },
};
