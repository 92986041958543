import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "SalaryDetail",
  components: {
    MainLayout,
    Loading
  },

  data() {
    return {
      salaryData: [],
      attendanceData: null,
      isLoading: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      start_value: '08:16:00',
      end_value: '11:00:00',
      editIndex: null,
      total: 0,
    };
  },

  methods: {
    filterNonNumeric(value, index) {
      if (value) {
        const amount = value.replace(/\D/g, '');
        this.attendanceData[index].late_fine_total = amount;
      }
    },

    filterNonNumericByOT(value, index) {
      if (value) {
        const amount = value.replace(/\D/g, '');
        this.attendanceData[index].ot_total = amount;
      }
    },

    getDetail() {
      axios.get(`salary/detail/${this.$route.params.id}`, { params: { campus_id: this.$store.state.user.campus_id } })
        .then((res) => {
          if (res.data) {
            this.salaryData = res.data.data;
            const teacher_id = this.salaryData.teacher_id;
            const adjustedMonth = new Date(this.salaryData.month);
            this.salaryData.month = adjustedMonth.toISOString().slice(0, 7);
            const month = this.salaryData.month;
            console.log(month);
            this.getAttendance(teacher_id, month)
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getAttendance(teacher_id, month) {
      axios.get('teacherAttendance/getAttendanceByTeacher', { params: {
        salary_id: this.$route.params.id,
        campus_id: this.$store.state.user.campus_id,
        teacher_id: teacher_id,
        month: month
      } })
      .then((res) => {
        if (res.data) {
          this.attendanceData = res.data;
          this.total = this.attendanceData.length;
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
      });

    },

    editAmount(index) {
      this.editIndex = null;
      this.editIndex = index;
    },

    confirm(index) {
      this.editIndex = null;
      console.log(this.attendanceData[index]);
    },

    updateAttendance() {
      axios.post('teacherAttendance/updateBySalary', {
        attendanceData: this.attendanceData,
        loginId: this.$store.state.user.id
      })
        .then(res => {
          if (res) {
            this.$notification.success("Salary Updated Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
          this.isLoading = true;
          location.reload();
          } else {
            this.$notification.error(res.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    excelDownload() {
      this.isLoading = true;
      axios.get(`/salary/excelDownloadByDetail/${this.$route.params.id}`, { 
        params: {
          month: this.salaryData.month
        }, 
        responseType: 'arraybuffer'
      })
        .then(response => {
          this.isLoading = false;
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `${this.salaryData.teacher_name}_${this.salaryData.month}.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    }

  },

  mounted() {
    this.isLoading = true;
    this.getDetail();
  },
};
