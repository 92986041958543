import Vue from 'vue'
import App from './App.vue'
import Vuelidate from 'vuelidate'
import router from './router/router.js'
import store from './store'
import './axios';
import VueNotification from '@kugatsu/vuenotification'
import moment from 'moment'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.use(VueNotification, {
  success: {
    background: "green",
    color: "white"
  }
});

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY');
  }
});

Vue.filter('numFormat', function (value) {
  if (value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return value;
  }
});

Vue.filter("nameFormat", function(value) {
  return value.length > 16 ? value.replace(/(<([^>]+)>)/gi, " ").substring(0, 16) + `...`: value;
});

Vue.filter("adminDisplayName", function (value) {
  if (value) {
    return value.length > 16 ? value.replace(/(<([^>]+)>)/gi, " ").substring(0, 16) + `...` : value;
  } else {
    return value;
  }
});

Vue.prototype.formatDateRange = function(joinDate, deletedAt) {
  if (joinDate) {
    const joinDateFormatted = moment(String(joinDate)).format('YYYY');
    const deletedAtFormatted = deletedAt ? moment(String(deletedAt)).format('YYYY') : 'Now';
    return joinDateFormatted + ' ~ ' + deletedAtFormatted;
  }
};

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
