import MainLayout from "@/components/layout/admin/MainLayout.vue";
import store from '../../store';
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import {
    required,
    maxLength
} from "vuelidate/lib/validators";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: "TeacherTypeCreate",
    components: {
        MainLayout,
        Loading
    },

    data() {
        return {
            isLoading: false,
            type_name: "",
            enable_qr: CONFIG.ENABLE_QR.DISABLE,
            submitted: false,
            disabled: false,
            typeExists: false,
        };
    },

    validations: {
        type_name: {
            required,
            maxLength: maxLength(20)
        },
    },

    methods: {
        async checkDuplicateType() {
            try {
                const response = await axios.get("/teacherType/checkDuplicateType", {
                    params: {
                        type_name: this.type_name,
                    },
                });
                this.typeExists = response.data.exists;
            } catch (error) {
                console.error('An error occurred:', error);
            }
        },

        async confirmRegister() {
            this.submitted = true;
            this.disabled = true;
            await this.checkDuplicateType();
            if (this.$v.$invalid || this.type_name == '' || this.type_name == 0 || this.typeExists) {
                this.disabled = false;
                return;
            }
            if (![CONFIG.ENABLE_QR.DISABLE, CONFIG.ENABLE_QR.ENABLE].includes(this.enable_qr)) {
                return;
            }
            this.register();
        },

        register() {
            this.disabled = true;
            var request = {
                type_name: this.type_name,
                login_id: store.state.user.id,
                campus_id: store.state.user.campus_id,
                enable_qr: this.enable_qr,
            }
            axios.post("/teacherType/create", request, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(response => {
                    if (response.data) {
                        this.$notification.success("Teacher Type Added Successfully", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        if (this.$route.query.rd_back == 'teacherType') {
                            this.$router.push({
                                name: "teacherTypeCreate"
                            });
                        } else {
                            this.$router.push({
                                name: "teacherTypeList"
                            });
                        }
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.disabled = false;
                    this.console.log("Teacher Type Create API Error", error.errors);
                });
        },

        showList() {
            this.$router.push({
                path: "/teacherType/list"
            });
        },
    },

};
