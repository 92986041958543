import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from 'axios';
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css'
export default {
    name: "inOutcomeList",
    components: {
        MainLayout,
        flatPickr,
        CONFIG,
        Select2Multiple,
        Loading,
        AdvancedLaravelVuePaginate,
    },

    data() {
        return {
            data: {},
            searchData: [],
            paymentMethodArr: null,
            noDataMsg: CONFIG.NO_DATA_FOUND,
            isLoading: false,
            start_date: '',
            end_date: '',
            campusId: this.$store.state.user.campus_id,
            acd_year_id: '',
            classArr: null,
            academicYear: [],

        };
    },

    methods: {
        getClass($yearId) {
            axios.get("class/classList/" + $yearId, {
                params: {
                    campus_id: this.$store.state.user.campus_id,
                }
            })
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Class'
                    }];
                    this.classArr = $defaultSelectOption.concat(res.data);
                    this.isLoading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },
        getThisAcademicYear() {
            this.isLoading = true;
            axios.get('period/getThisPeriod')
                .then(res => {
                    this.academicYear = res.data;
                    this.isLoading = false;
                    this.getClass(this.academicYear.id)
                    this.getList();
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },
        getPaymentMethod() {
            axios.get('paymentMethod/getPaymentMethodArray')
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Payment Method'
                    }];
                    this.paymentMethodArr = $defaultSelectOption.concat(res.data[0]);

                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getList(page = 1) {
            this.isLoading = true
            axios.get('transaction/list?page=' + page, 
                { params: { campus_id: this.campusId, acd_year_id: this.academicYear.id } })
                .then(res => {
                    if (res.data) {
                        this.data = res.data
                    }
                    this.isLoading = false;
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });

        },

        confirmDelete(id, status) {
            if (window.confirm("Are you sure you want to delete?")) {
                this.delete(id, status);
            } else {
                console.log("no");
            }
        },
        delete(id) {
            this.isLoading = true;
            axios.delete(`transaction/delete/${id}`)
                .then(res => {
                    if (res.data)
                        this.getList();
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        reset() {
            this.searchData = [];
        },

        search(page = 1) {
            this.isLoading = true;
            axios.get('/transaction/search?page=' + page, {
                params: {
                    title: this.searchData.title,
                    fromAmt: this.searchData.fromAmt,
                    toAmt: this.searchData.toAmt,
                    payMethod: this.searchData.payMethod,
                    start_date: this.searchData.start_date,
                    end_date: this.searchData.end_date,
                    campus_id: this.campusId,
                    acd_year_id: this.academicYear.id,
                    type: this.searchData.type,
                    class_id: this.searchData.class_id,
                    invoice_number: this.searchData.invoice_number,
                }
            })
                .then(res => {
                    if (res.data) {
                        this.data = res.data;
                    }
                    this.isLoading = false;
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        excelDownload() {
            this.isLoading = true;
            axios.get('/transaction/excelDownload', {
                params: {
                    title: this.searchData.title,
                    fromAmt: this.searchData.fromAmt,
                    toAmt: this.searchData.toAmt,
                    payMethod: this.searchData.payMethod,
                    start_date: this.searchData.start_date,
                    end_date: this.searchData.end_date,
                    campus_id: this.campusId,
                    acd_year_id: this.academicYear.id,
                    type: this.searchData.type,
                    class_id: this.searchData.class_id,
                    invoice_number: this.searchData.invoice_number
                },
                responseType: 'arraybuffer'
            })
                .then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    let date = new Date();
                    fileLink.setAttribute('download', `transaction_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate()}.xlsx`);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.isLoading = false;
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        }
    },

    mounted() {
        this.isLoading = true;
        this.getThisAcademicYear();
        this.getPaymentMethod();
    },
};
