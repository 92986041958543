import Loading from 'vue-loading-overlay';
import Select2Multiple from "v-select2-multiple-component";
import 'vue-loading-overlay/dist/vue-loading.css';
import {
    required,
    maxLength,
} from "vuelidate/lib/validators";
import axios from "axios";

export default {
    name: "CategoryItemModal",
    components: {
        Loading,
        Select2Multiple,
    },

    props: {
        modalId: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: false,
            default: 'Category Item'
        },
        status: {
            type: String,
            required: true,
        },
        inputTypeArr: {
            required: true
        },
        categoryItemUpdate: {
            type: Object,
            required: false,
        }
    },

    data() {
        return {
            errors: [],
            submitted: false,
            disabled: false,
            isLoading: false,
            generalError: '',
            categoryItemData: {
                id: '',
                name: '',
                description: '',
            },
            hasNameError: false,
            nameErrorMessage: '',
        };
    },

    watch: {
        categoryItemUpdate: {
            immediate: false,
            handler(newVal) {
                if (this.status == 'Update' && newVal) {
                    this.cloneUpdateData(newVal);
                }
            }
        }
    },

    validations: {
        categoryItemData: {
            name: {
                required,
                maxLength: maxLength(255),
            },
        }
    },

    methods: {
        reset() {
            // Reset the properties directly
            this.categoryItemData.name = '';
            this.categoryItemData.description = '';
            this.hasNameError = false;

            // If the status is 'Update', clone the update data
            if (this.status === 'Update') {
                this.cloneUpdateData(this.categoryItemUpdate);
            }

            // Reset the submitted state
            this.submitted = false;
        },

        submitFormItem() {
            if (this.disabled) return; // Prevent multiple submissions

            this.disabled = true;
            this.submitted = true;
            this.isLoading = true;

            if (this.$v.$invalid) {
                this.disabled = false; // Re-enable button on validation error
                this.isLoading = false;
                return;
            }

            if (this.status === 'Create') {
                this.createFromItem();
            } else if (this.status === 'Update') {
                this.updateFormItem();
            }
        },

        cloneUpdateData(newVal) {
            this.categoryItemData = { ...newVal };
        },

        createFromItem() {
            this.isLoading = false;
            if (this.$v.$invalid) return;
            let request = {
                name: this.categoryItemData.name,
                description: this.categoryItemData.description,
            }
            axios.post('category/create', request, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => {
                    if (res.data.success == true) {
                        this.hasNameError = false;
                        this.$emit('callGetItemsList');
                        this.$bvModal.hide(this.modalId);
                        this.$notification.success("Category Create Successfully", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.isLoading = false;
                        this.disabled = false;
                    } else {
                        this.disabled = false;
                        this.$bvModal.hide(this.modalId);
                        this.isLoading = false;
                        this.$notification.error("Something Wrong on Create", {
                            timer: 3,
                            position: "bottomCenter",
                        });
                    }
                }).catch((error) => {
                    this.disabled = false;
                    if (error.response) {
                        if (error.response.status == 422) {
                            this.isLoading = false;
                            console.error("Validation error:", error.response.data.errors);
                            if (error.response.data.errors.name) {
                                this.hasNameError = true;
                                this.nameErrorMessage = error.response.data.errors.name[0];
                            }
                         } else if (error.response.status == 401) {
                            this.$store.commit('logout');
                            window.location.replace('login');
                        }
                    }
                });
        },

        updateFormItem() {
            this.isLoading = false;
            if (this.$v.$invalid) return;
            let request = {
                id: this.categoryItemUpdate.id,
                name: this.categoryItemData.name,
                description: this.categoryItemData.description,
            }
            axios.put('category/update', request, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => {
                    if (res.data.success) {
                        this.hasNameError = false;
                        this.$emit('callGetItemsList');
                        this.$bvModal.hide(this.modalId);
                        this.$notification.success("Category Updated Successfully", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                    } else {
                        this.$notification.error("Something went wrong on Update", {
                            timer: 3,
                            position: "bottomCenter",
                        });
                    }
                    this.disabled = false;
                }).catch((error) => {
                    this.disabled = false;
                    if (error.response) {
                        if (error.response.status == 422) {
                            this.isLoading = false;
                            this.hasNameError = true;
                            console.error("Validation error:", error.response.data.errors);
                            if (error.response.data.errors.name) {
                                this.hasNameError = true;
                                this.nameErrorMessage = error.response.data.errors.name[0];
                            }
                        } else if (error.response.status == 401) {
                            this.$store.commit('logout');
                            window.location.replace('login');
                        }
                    }
                });
        }
    },
};
