import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Footer from "@/components/layout/admin/FooterPage.vue";

export default {
    name: "aboutUs",

    components: {
        Footer,
        Loading
    },

    methods: {
    },

    mounted() {
        this.isLoading = true;
    },
};
