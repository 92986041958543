import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import CONFIG from "@/assets/js/config.js";
import store from '../../store';

export default {
    name: "DamageLossesList",
    components: {
        MainLayout,
        flatPickr,
        Select2Multiple,
        AdvancedLaravelVuePaginate,
        Loading,
    },

    data() {
        return {
            damageLossesData: {},
            searchData: {
                name: '',
                book_name: '',
                book_status: null,
                description: '',
            },
            returnStatusArr: [
                { id: 0, text: 'Select Status' },
                { id: 1, text: 'Damage' },
                { id: 2, text: 'Loss' },
            ],
            selectedStatus: 0,
            selectedCategory: 0,
            selectedType: 0,
            noDataMsg: CONFIG.NO_DATA_FOUND,
            isLoading: false,
            search_status: false,
            searchBtnStatus: false,
            campusId: '',
        };
    },

    methods: {
        changeEvent(val, fieldName) {
            fieldName == 'status' && val == 0 ? this.$set(this, fieldName, '') : this.$set(this, fieldName, val);
        },

        getDamageLossesList(page = 1) {
            var request = {
                params: {
                    campus_id: store.state.user.campus_id
                }
            }
            axios.get("/damageAndLoss/list?page=" + page, request)
                .then(response => {
                    this.search_status = false;
                    this.damageLossesData = response.data;
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Damage and Losses List API Error", error);
                });
        },

        reset() {
            this.searchData.name = ''
            this.searchData.book_name = ''
            this.searchData.book_status = null
            this.searchData.description = null
        },

        searchDamageLosses(page = 1) {
            this.isLoading = true;
            var request = {
                params: {
                    name: this.searchData.name,
                    book_name: this.searchData.book_name,
                    book_status: this.searchData.book_status,
                    description: this.searchData.description,
                    campus_id: this.$store.state.user.campus_id
                }
            }

            axios.get("/damageAndLoss/search?page=" + page, request)
                .then(response => {
                    this.search_status = true;
                    this.damageLossesData = response.data;
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Damage and Losses Search API Error", error);
                });
        },

        confirmDelete(id) {
            if (window.confirm("Are you sure you want to delete?")) {
                this.delete(id);
            }
        },

        delete(id) {
            axios.delete("/damageAndLoss/delete/" + id)
                .then(res => {
                    if (res.data.success == true) {
                        this.isLoading = true;
                        this.getDamageLossesList();
                        this.$notification.success("Damage and Loss Data Deleted Successfully.", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                    }
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                })
        },

        showCreate() {
            this.$router.push({
                path: "/damageLosses/create"
            });
        },

        showEdit(id) {
            this.$router.push({
                name: "damageLossesEdit",
                params: {
                    id: id
                }
            });
        },

        showDetail(classId) {
            this.$router.push({
                name: "damageLossesDetail",
                params: {
                    id: classId
                }
            });
        },

        formatDescription(description) {
            return description ? description.replace(/\n/g, '<br>') : '';
        },
    },

    mounted() {
        this.campusId = this.$store.state.user.campus_id;
        this.isLoading = true;
        this.getDamageLossesList();
    },
};
