import Select2Multiple from 'v-select2-multiple-component';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
import {
    required,
} from "vuelidate/lib/validators";
import store from '@/store'

export default {
    name: "DamageLossesEdit",
    components: {
        MainLayout,
        Select2Multiple,
        flatPickr,
        Loading,
    },

    data() {
        return {
            damageLossesData: [],
            memberArr: null,
            bookArr: null,
            isError: false,
            submitted: false,
            isLoading: false,
            campusId: store.state.user.campus_master.campus_code,
            disabled: false,
            noDataMsg: CONFIG.NO_DATA_EXIST,
            noMemberMsg: '',
            noCategoryMsg: '',
            noBookMsg: '',
        };
    },

    validations: {
        damageLossesData: {
            book_id: {
                required,
            },
            status: {
                required
            },
            description: {
                required,
            },
        },
    },

    computed: {
        isValidMember() {
            if (!this.memberArr || this.memberArr.length === 0) return false;
            return this.memberArr.some(
                (library_member_id) => Number(library_member_id.id) === Number(this.damageLossesData.library_member_id)
            );
        },
        isValidBook() {
            if (!this.bookArr || this.bookArr.length === 0) return false;
            return this.bookArr.some(
                (book_id) => Number(book_id.id) === Number(this.damageLossesData.book_id)
            );
        },
    },


    methods: {
        getMember() {
            let $defaultSelectOption = [{
                'id': 0,
                'text': 'Select Member'
            }];
            axios.get("/member/getList", {
                params: {
                    campus_id: this.$store.state.user.campus_id,
                }
            })
                .then(res => {
                    if (res.data.data.length > 0) {
                        this.memberArr = $defaultSelectOption.concat(res.data.data);
                    } else {
                        this.memberArr = $defaultSelectOption;
                    }
                    this.isLoading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getBookList() {
            this.isLoading = true;
            const campusId = this.$store.state.user.campus_id;
            axios.get(`book/booklistforchoose/${campusId}`)
                .then((res) => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Book'
                    }];

                    if (res.data && res.data.data && res.data.data.length > 0) {
                        let bookOptions = res.data.data.map(book => ({
                            id: book.id,
                            text: book.title
                        }));

                        this.bookArr = $defaultSelectOption.concat(bookOptions);
                        this.noBookMsg = '';
                    } else {
                        this.bookArr = $defaultSelectOption;
                        this.noBookMsg = 'No Book for this campus.';
                    }

                    this.isLoading = false;
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    } else {
                        console.error("Error fetching book list:", error);
                    }
                    this.isLoading = false;
                });
        },

        onMemberChange(selectedMember) {
            if (selectedMember.id === null) {
                this.damageLossesData.library_member_id = null;
            } else {
                this.damageLossesData.library_member_id = selectedMember.id;
            }
        },

        getDetail() {
            axios.get('damageAndLoss/detail/' + this.$route.params.id)
                .then((response) => {
                    if (response.data != undefined) {
                        this.damageLossesData = response.data;
                    }
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Damage and Losses Detail API Error", error);
                })

        },

        update() {
            this.submitted = true;
            if (this.$v.$invalid || this.isError) {
                return;
            }
            // Convert 0 to null before sending
            const payload = {
                ...this.damageLossesData,
                library_member_id: this.damageLossesData.library_member_id === '0' ? null : this.damageLossesData.library_member_id
            };

            axios.post(`damageAndLoss/update/${this.$route.params.id}`, payload)
                .then(res => {
                    if (res.data.success) {
                        this.$notification.success("Damage and Loss Updated Successfully", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.$router.push({ name: "damageLossesList" });
                    } else {
                        this.disabled = false;
                        this.$notification.error(res.data.message, {
                            timer: 3,
                            position: "bottomCenter"
                        });
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    if (error.response.status === 422) {
                        this.$notification.error("Validation Error: " + error.response.data.message, {
                            timer: 5,
                            position: "bottomCenter"
                        });
                    }
                });
        }

    },

    mounted() {
        this.isLoading = true;
        this.getDetail();
        this.getMember();
        this.getBookList();
    },
};
